export default {
  product: null,
  user: {
    email: null
  },
  start: {
    date: null
  },
  due: {
    date: null
  },
  banner: {
    type: null,
    quantity: 0,
    frame: null,
    status: null,
    master_quantity: 0,
    variation: null,
    storyboard: null,
    psd: null
  },
  edm: {
    type: null,
    status: null,
    design: null
  },
  video: {
    type: null,
    duration: null,
    totalHours: null,
    isWithAssets: null,
    total: {
      label: "",
      value: 0
    }
  },
  detail: {
    jobNumber: null,
    description: null,
    downloadURLs: ""
  }
};
