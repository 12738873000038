<template>
  <div class="page page--web-banner-quantity">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1
            class="font-primary--light"
            v-html="tempdata.banner.type.title"
          ></h1>
          <div class="row">
            <div :class="quantityClassCss">
              <TextInput
                placeholder="How many banners?"
                label="Banners"
                type="number"
                name="bannersQuantity"
                :value="bannerQuantity || ''"
                :error="!bannerQuantityValidation.isValid"
                :errorMessage="bannerQuantityValidation.message"
                :skipDirtyCheck="skipDirtyCheck"
                @onChange="bannerQuantityChange"
              />
            </div>
            <div class="form-group col-lg-6" v-if="isFrame">
              <SelectDropdown
                placeholder="How many frames from each banners?"
                label="Frames"
                :value="tempdata.banner.frame"
                :options="this.banner.frames.list"
                :error="!bannerFrameValidation.isValid"
                :errorMessage="bannerFrameValidation.message"
                :skipDirtyCheck="skipDirtyCheck"
                @onChange="frameQuantityChange"
              />
            </div>
          </div>
          <NavigationPage
            :next-to="nextTo"
            :prev-to="prevTo"
            @on-next-to="onNextTo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WEB_BANNER, STC } from "@/library/constants";
import { mapGetters } from "vuex";
import NavigationPage from "@/components/NavigationPage.vue";
import TextInput from "../../components/TextInput";
import SelectDropdown from "@/components/SelectDropdown";

export default {
  name: "BannerQuantity",
  components: {
    NavigationPage,
    TextInput,
    SelectDropdown
  },
  data() {
    return {
      skipDirtyCheck: false
    };
  },
  computed: {
    ...mapGetters(["tempdata", "banner"]),
    quantityClassCss() {
      return ["form-group", this.isFrame ? "col-lg-6" : "col-12"];
    },
    productType() {
      return this.tempdata.product && this.tempdata.product.id
        ? this.tempdata.product.id
        : "";
    },
    bannerType() {
      return this.tempdata.banner.type && this.tempdata.banner.type.id
        ? this.tempdata.banner.type.id
        : "";
    },
    isFrame() {
      return this.productType === WEB_BANNER && this.bannerType !== STC;
    },
    prevTo() {
      return "banner-type";
    },
    nextTo() {
      var rn = "";
      if (this.isFrame) {
        if (
          this.bannerQuantityValidation.isValid &&
          this.bannerFrameValidation.isValid
        ) {
          rn = "banner-status";
        }
      } else {
        if (this.bannerQuantityValidation.isValid) {
          rn = "banner-status";
        }
      }
      return rn;
    },
    bannerQuantity: {
      set(value) {
        this.$store.dispatch("setBannerQuantity", value);
      },
      get() {
        return this.tempdata.banner.quantity;
      }
    },
    selectedFrame: {
      set(value) {
        this.$store.dispatch("setBannerFrame", value);
      },
      get() {
        return this.tempdata.banner.frame;
      }
    },
    bannerQuantityValidation() {
      if (!this.bannerQuantity || this.bannerQuantity === {}) {
        return {
          isValid: false,
          message: "Banner quantity is required"
        };
      }

      if (typeof this.bannerQuantity !== "number") {
        return {
          isValid: false,
          message: "Must be a valid number format"
        };
      }

      if (Number(this.bannerQuantity) < 1) {
        return {
          isValid: false,
          message: "Must be at least 1 banner"
        };
      }

      if (!Number.isInteger(Number(this.bannerQuantity))) {
        return {
          isValid: false,
          message: "Must be an integer value"
        };
      }

      return {
        isValid: true,
        message: ""
      };
    },
    bannerFrameValidation() {
      if (!this.selectedFrame) {
        return {
          isValid: false,
          message: "Banner frames is required"
        };
      }
      return {
        isValid: true,
        message: ""
      };
    }
  },
  methods: {
    bannerQuantityChange(value) {
      this.bannerQuantity = Number(value);
    },
    frameQuantityChange(value) {
      this.selectedFrame = value;
    },
    validateNow() {
      this.skipDirtyCheck = true;
    },
    onNextTo() {
      if (!this.nextTo) {
        this.skipDirtyCheck = true;
      } else {
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      }
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 2);
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.banner.type) {
        next();
      } else {
        next({ name: "banner-type" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
