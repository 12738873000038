<template>
  <div class="card-group">
    <div class="card-grid" v-for="(val, index) in cards" :key="index">
      <div
        :class="cardStyle(val)"
        @click="selectCard(val)"
        @mouseover="playVideo"
        @mouseleave="pauseVideo"
      >
        <div class="card__icon" v-if="val.icon && type === 'icon'">
          <object
            type="image/svg+xml"
            class="card__icon--active"
            :data="require(`@/assets/images/icon/${val.icon}.svg`)"
          ></object>
          <object
            type="image/svg+xml"
            class="card__icon--hover"
            :data="require(`@/assets/images/icon/${val.icon}-active.svg`)"
          ></object>
        </div>
        <video
          loop
          muted
          class="card__video"
          data-object-fit="cover"
          data-object-position="center center"
          v-if="val.video && type === 'video'"
        >
          <source
            :src="require(`@/assets/videos/${val.video}`)"
            type="video/mp4"
          />
        </video>
        <div class="card__label font-primary--bold" v-html="val.label"></div>
      </div>
      <div class="card__summary">
        <p v-if="val.summary">
          {{ val.summary }}
        </p>
        <a href="javascript:;" v-if="val.modal" @click="showModal(val)">
          More&nbsp;info&nbsp;>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardButton",
  data() {
    return {
      cards: this.data,
      datamodal: "Modal"
    };
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    defaultSelected: {
      type: Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: String,
      default: "icon"
    }
  },
  methods: {
    selectCard(val) {
      const { data } = this;
      this.cards = data.map(item => {
        if (item.id === val.id) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
        return item;
      });
      this.$emit("selectcard", val);
    },
    showModal(val) {
      let modal = { status: true, data: val };
      this.$store.dispatch("setModal", modal);
    },
    isSameAsDefault(val) {
      return (
        this.defaultSelected &&
        this.defaultSelected.id &&
        val.id === this.defaultSelected.id
      );
    },
    cardStyle(val) {
      return [
        "card",
        val.isActive || this.isSameAsDefault(val) ? "active" : "",
        !val.icon && this.type === "icon" ? "no-icon" : this.type
      ];
    },
    playVideo(e) {
      const video = e.target.querySelector("video");
      if (video) {
        e.target.querySelector("video").play();
      }
    },
    pauseVideo(e) {
      const video = e.target.querySelector("video");
      if (video) {
        e.target.querySelector("video").pause();
      }
    }
  }
};
</script>
