<template>
  <div class="page page--result-detail">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-10 wrapper">
          <h1 class="title font-primary--bold">
            {{ 'Enter Details' }}
          </h1>
          <span class="quote-id">Quote ID : {{ quoteID }}</span>
          <div class="row">
            <div class="col-12">
              <p class="label">
                {{ 'JOB NUMBER / JOB NAME:' }}
                <span>*</span>
              </p>
              <TextInput
                type="text"
                name="jobNumber"
                :value="jobNumber"
                @onChange="onJobNumberChange"
                placeholder="Enter job number / job name here"
                :error="!jobNumberValidation.isValid"
                :errorMessage="jobNumberValidation.message"
                :skipDirtyCheck="true"
              ></TextInput>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="label">
                {{ 'START:' }}
                <span>*</span>
              </p>
              <DatePicker
                :error="!startDateValidation.isValid"
                :errorMessage="startDateValidation.message"
                :firstRender="isStartDateFirstRender"
                idDateTime="start-date"
                @onChange="onStartDateChange"
                @onClose="onStartDateClose"
              />
            </div>
            <div class="col-sm-12 col-md-6">
              <p class="label">
                {{ 'DUE:' }}
                <span>*</span>
              </p>
              <DatePicker
                :error="!dueDateValidation.isValid"
                :errorMessage="dueDateValidation.message"
                :firstRender="isDueDateFirstRender"
                idDateTime="due-date"
                @onChange="onDueDateChange"
                @onClose="onDueDateClose"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="label">
                {{ 'DELIVERABLE DESCRIPTIONS:' }}
                <span>*</span>
              </p>
              <TextArea
                rows="4"
                :resizeable="false"
                :value="description"
                :error="!descriptionValidation.isValid"
                :errorMessage="descriptionValidation.message"
                @onChange="onDescriptionChange"
                label="E.g. Helpful text identifying the type HTML5 Banners - e.g. Creative Route 1 / Launch banners / DCM / Sizmek / Rich Media etc"
              ></TextArea>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <p class="label">
                {{ 'UPLOAD FILES:' }}
              </p>
              <upload-files @onChange="onUploadFileChange"></upload-files>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <p class="label">
                {{ 'LARGE FILE ASSETS:' }}
              </p>
              <TextInput
                type="text"
                name="assetsURL"
                :value="downloadURLs"
                @onChange="onDownloadURLChange"
                placeholder="Enter file download URL"
                classWrap="assets-url"
                label="If you have working files large than 100MB"
              ></TextInput>
            </div>
          </div>

          <div class="row button-wrapper">
            <div class="col-6">
              <button class="btn btn-primary back-btn left-icon" @click.prevent="backTo">
                <span class="caret">
                  {{ 'back' }}
                  <span class="icon"></span>
                </span>
              </button>
            </div>
            <div class="col-6 text-right">
              <button :class="classButtonSubmit" @click.prevent="onCreateTask">
                <span class="caret">
                  {{ isCtaRequest ? 'submitted' : 'submit' }}
                  <span class="icon" v-if="!isCtaRequest"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TextInput from '@/components/TextInput'
import TextArea from '@/components/TextArea'
import DatePicker from '@/components/DatePicker'
import { createWrikeTask } from '@/services/wrike'
import { createWrikeAttachment } from '@/services/wrikeAttachment'
import { sendEdm } from '@/services/ogilvy'
import edmtemplate from '@/library/email-template'
import moment from 'moment-timezone'
import UploadFiles from '@/components/UploadFiles'

export default {
  name: 'ResultDetail',
  components: {
    TextInput,
    TextArea,
    DatePicker,
    UploadFiles
  },
  // props: ["files"],
  data() {
    return {
      isCreateTask: false,
      isStartDateRequired: false,
      isDueDateRequired: false,
      isJobNumberValid: false,
      isStartDateValid: false,
      isDueDateValid: false,
      isDescriptionValid: false,
      isCtaRequest: false,
      isCreateTaskValid: false,
      isFilesValid: true,
      isStartDateFirstRender: true,
      isDueDateFirstRender: true,
      file: '',
      fileDatas: []
    }
  },
  mounted() {
    this.$store.dispatch('setGrid', 1)
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem('tempdata')) {
      let tempdata = JSON.parse(localStorage.getItem('tempdata'))
      if (tempdata.product.id) {
        next()
      } else {
        next({ name: 'HomeEstimator' })
      }
    } else {
      next({ name: 'HomeEstimator' })
    }
  },
  computed: {
    ...mapGetters(['banner', 'tempdata', 'cost', 'breakdown', 'quoteID']),

    classButtonSubmit() {
      return [
        'btn btn-primary btn-submit',
        this.isCreateTask ? 'active' : '',
        this.isCtaRequest ? 'disabled' : '',
        !this.isCreateTaskValid ? 'disabled' : '',
        !this.isFilesValid ? 'disabled' : ''
      ]
    },
    jobNumberValidation() {
      if (!this.isJobNumberValid) {
        return {
          isValid: false,
          message: 'Job number / job name is required.'
        }
      } else {
        return {
          isValid: true,
          message: ''
        }
      }
    },
    startDateValidation() {
      this.onStartDateChange()
      if (this.isStartDateRequired) {
        return {
          isValid: false,
          message: 'Start date is required.'
        }
      } else if (!this.isStartDateValid) {
        return {
          isValid: false,
          message: 'Start date must lower than due date.'
        }
      } else {
        return {
          isValid: true,
          message: ''
        }
      }
    },
    dueDateValidation() {
      this.onDueDateChange()
      if (this.isDueDateRequired) {
        return {
          isValid: false,
          message: 'Due date is required.'
        }
      } else if (!this.isDueDateValid) {
        return {
          isValid: false,
          message: 'Due date must more than start date.'
        }
      } else {
        return {
          isValid: true,
          message: ''
        }
      }
    },
    descriptionValidation() {
      if (!this.isDescriptionValid) {
        return {
          isValid: false,
          message: 'Deliverable descriptions is required.'
        }
      } else {
        return {
          isValid: true,
          message: ''
        }
      }
    },
    jobNumber: {
      set(jobNumber) {
        const description = this.tempdata.detail.description
        const downloadURLs = this.tempdata.detail.downloadURLs
        this.$store.dispatch('setDetail', {
          jobNumber: jobNumber,
          downloadURLs: downloadURLs,
          description: description
        })
      },
      get() {
        return this.tempdata.detail.jobNumber
      }
    },
    description: {
      set(description) {
        const jobNumber = this.tempdata.detail.jobNumber
        const downloadURLs = this.tempdata.detail.downloadURLs
        this.$store.dispatch('setDetail', {
          jobNumber: jobNumber,
          downloadURLs: downloadURLs,
          description: description
        })
      },
      get() {
        return this.tempdata.detail.description
      }
    },
    downloadURLs: {
      set(downloadURLs) {
        const description = this.tempdata.detail.description
        const jobNumber = this.tempdata.detail.jobNumber
        this.$store.dispatch('setDetail', {
          jobNumber: jobNumber,
          downloadURLs: downloadURLs,
          description: description
        })
      },
      get() {
        return this.tempdata.detail.downloadURLs
      }
    },
    totalCost() {
      return this.cost
        ? Math.round(this.cost)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        : 0
    },
    taskDescription() {
      let d = new Date()
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      const formattedDate = `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`

      const hours = d.getHours()
      let minutes = d.getMinutes()
      const formattedMinutes = ('0' + minutes).slice(-2)
      const currentTime = `${hours}:${formattedMinutes}`

      const downloadURLs = this.tempdata.detail.downloadURLs ? this.tempdata.detail.downloadURLs : '-'

      var des = ''
      for (var a of this.breakdown) {
        des += `<h5>${a.label}</h5>`
        des += `<p>${a.value}</p>`
        des += `<br>`
      }
      des += `<h5>Total Cost</h5>`
      des += `<p>${this.totalCost} AUD</p>`
      des += `<h5>User Email</h5>`
      des += `<p>${this.tempdata.user.email}</p>`
      des += `<h5>Deliverable Descriptions:</h5>`
      des += `<p>${this.tempdata.detail.description}</p>`
      des += `<h5>Download URLs</h5>`
      des += `<p>${downloadURLs}</p>`
      des += `<h5>Created on</h5>`
      des += `<p>${formattedDate} ${currentTime}</p>`
      return des
    },
    taskTitle() {
      const productTitle = this.tempdata.product.title
      const jobNumber = this.tempdata.detail.jobNumber
      const taskTitle = `${jobNumber} - ${productTitle}`

      return taskTitle
    },
    quoteID() {
      var uid = new Date()
        .getTime()
        .toString(36)
        .toUpperCase()
      var email = this.tempdata.user.email
      var ex = email.toString().split('@')
      var quoteID = `${ex[1].slice(0, 3).toUpperCase()}${uid}`

      this.$store.dispatch('setQuoteID', quoteID)
      return quoteID
    }
  },
  methods: {
    checkAllValidation() {
      if (
        this.jobNumber &&
        this.jobNumber != '' &&
        this.isStartDateValid &&
        this.tempdata.start.date != '' &&
        this.isDueDateValid &&
        this.tempdata.due.date != '' &&
        this.description &&
        this.description != ''
      ) {
        this.isCreateTaskValid = true
      } else {
        this.isCreateTaskValid = false
      }
    },
    onJobNumberChange(val) {
      if (val == '') {
        this.isJobNumberValid = false
      } else {
        this.isJobNumberValid = true
      }
      this.jobNumber = val
      this.checkAllValidation()
    },
    onStartDateChange() {
      this.isStartDateFirstRender = false
      if (this.tempdata.start.date > this.tempdata.due.date && this.tempdata.due.date != null) {
        this.isStartDateValid = false
      } else {
        this.isStartDateValid = true
        if (this.tempdata.due.date != null) {
          this.isDueDateValid = true
        }
      }
      this.checkAllValidation()
    },
    onDueDateChange() {
      this.isDueDateFirstRender = false
      if (this.tempdata.due.date < this.tempdata.start.date && this.tempdata.start.date != null) {
        this.isDueDateValid = false
      } else {
        this.isDueDateValid = true
        if (this.tempdata.start.date != null) {
          this.isStartDateValid = true
        }
      }
      this.checkAllValidation()
    },
    onStartDateClose(val) {
      this.isStartDateFirstRender = false
      if (!val) {
        this.isStartDateRequired = true
      } else {
        this.isStartDateRequired = false
      }
    },
    onDueDateClose(val) {
      this.isDueDateFirstRender = false
      if (!val) {
        this.isDueDateRequired = true
      } else {
        this.isDueDateRequired = false
      }
    },
    onDescriptionChange(val) {
      if (val == '') {
        this.isDescriptionValid = false
      } else {
        this.isDescriptionValid = true
      }
      this.description = val
      this.checkAllValidation()
    },
    onDownloadURLChange(val) {
      this.downloadURLs = val
    },
    onUploadFileChange(files) {
      this.fileDatas = files
      this.filesValidation(this.fileDatas)
    },
    onCreateTask() {
      if (!this.isCreateTask && this.isCreateTaskValid && this.isFilesValid) {
        this.isCreateTask = true
        var startDate = ''
        var dueDate = ''

        startDate = moment(this.tempdata.start.date).format('yyyy-MM-DD')
        dueDate = moment(this.tempdata.due.date).format('yyyy-MM-DD')

        // startDate = this.tempdata.start.date;
        // dueDate = this.tempdata.due.date;
        // let taskId = "IEABJB5WKQSBOE7G";
        // this.onCreateWrikeAttachments(this.fileDatas, taskId);
        try {
          createWrikeTask({
            title: this.taskTitle,
            description: this.taskDescription.replace(/\r?\n/g, '<br />'),
            responsibles: ['KUAEX5AI', 'KUALLWSY', 'KUADI2K5', 'KUASBMFF'],
            dates: {
              start: startDate,
              due: dueDate
            },
            customFields: [
              {
                id: 'IEABJB5WJUABUI3W',
                value: this.quoteID
              },
              {
                id: 'IEABJB5WJUABUCYB',
                value: this.totalCost
              }
            ]
          }).then((res) => {
            if (res.status !== 200) {
              this.isCreateTask = false
            } else {
              let taskId = res.data.data[0].id
              this.onCreateWrikeAttachments(this.fileDatas, taskId)
              this.isCtaRequest = true
            }
          })
          sendEdm({
            from: 'no-reply@production.bullseye-digital.com',
            to: this.tempdata.user.email,
            subject: 'Cost Estimator',
            html: edmtemplate({ hideElement: false })
          })
        } catch (e) {
          console.log(e)
          this.isCreateTask = false
        }
      } else {
        if (!this.jobNumber || this.jobNumber == '') {
          this.isJobNumberValid = false
        }
        if (this.tempdata.start.date > this.tempdata.due.date && this.tempdata.start.date && this.tempdata.due.date) {
          this.isStartDateValid = false
          this.isStartDateFirstRender = false
        }
        if (this.tempdata.due.date < this.tempdata.start.date && this.tempdata.start.date && this.tempdata.due.date) {
          this.isDueDateValid = false
          this.isDueDateFirstRender = false
        }
        if (this.tempdata.start.date == '' || !this.tempdata.start.date) {
          this.isStartDateRequired = true
          this.isStartDateFirstRender = false
        }
        if (this.tempdata.due.date == '' || !this.tempdata.due.date) {
          this.isDueDateRequired = true
          this.isDueDateFirstRender = false
        }
        if (!this.description || this.description == '') {
          this.isDescriptionValid = false
        }
      }
    },
    onCreateWrikeAttachments(files, taskId) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i].file
        if (files[i].success) {
          this.onCreateWrikeAttachment(file, taskId)
        }
      }
    },
    onCreateWrikeAttachment(file, taskId) {
      // let taskId = "IEABJB5WKQSBOE7G";
      // let file = files[0];
      // debugger;
      try {
        createWrikeAttachment(file, taskId).then((res) => {
          console.log(res)
        })
      } catch (e) {
        console.log(e)
        // this.isCreateTask = false;
      }
    },
    filesValidation(files) {
      this.isFilesValid = true
      for (let i = 0; i < files.length; i++) {
        if (!files[i].success) {
          this.isFilesValid = false
        }
      }
    },
    backTo() {
      this.$router.go(-1)
    }
  }
}
</script>
