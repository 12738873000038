<template>
  <div class="page page--web-banner-type">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="banner.type.title"></h1>
          <card-button
            :data="banner.type.list"
            @selectcard="selectBannerType"
            :default-selected="tempdata.banner.type"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage";

export default {
  name: "BannerType",
  components: {
    CardButton,
    NavigationPage
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters(["banner", "tempdata"]),
    prevTo() {
      return "Product";
    },
    nextTo() {
      return (
        this.tempdata.banner.type &&
        this.tempdata.banner.type.id &&
        "banner-quantity"
      );
    }
  },
  methods: {
    selectBannerType(val) {
      if (val.published) {
        this.$store.dispatch("setBannerType", val);
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
        this.$router.push({ name: val.router_name });
      }
    }
  },
  mounted() {
    this.$store.dispatch("setBannerQuantity", 0);
    this.$store.dispatch("setBannerFrame", null);
    this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
    this.$store.dispatch("setGrid", 4);
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.product && tempdata.product.id) {
        next();
      } else {
        next({ name: "Product" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
