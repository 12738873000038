<template>
  <div class="page page--banner-storyboard">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="edm.design.title"></h1>
          <card-button
            :data="edm.design.list"
            @selectcard="selectDesign"
            :default-selected="tempdata.edm.design"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage";
import { CCG } from "@/library/constants";

export default {
  name: "EdmDesign",
  components: {
    CardButton,
    NavigationPage
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["edm", "tempdata"]),
    prevTo() {
      const TYPE =
        this.tempdata.edm.type && this.tempdata.edm.type.id
          ? this.tempdata.edm.type.id
          : "";
      switch (TYPE) {
        case CCG:
          return "edm-type";
        default:
          return "edm-status";
      }
    },
    nextTo() {
      return (
        this.tempdata.edm.design && this.tempdata.edm.design.id && "result"
      );
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 2);
  },
  methods: {
    selectDesign(val) {
      this.$store.dispatch("setEdmDesign", val);
      this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      this.$router.push({ name: val.router_name });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.edm.type) {
        if (tempdata.edm.type.id === CCG) {
          next();
        } else {
          if (tempdata.edm.status && tempdata.edm.status.id) {
            next();
          } else {
            next({ name: "edm-status" });
          }
        }
      } else {
        next({ name: "edm-type" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
