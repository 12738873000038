<template>
  <div class="page page--master-quantity">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="title"></h1>
          <p>
            {{ tempdata.banner.status.subtitle }}
          </p>

          <TextInput
            placeholder="How many master?"
            label="Master Banners"
            type="number"
            name="masterBanners"
            :value="masterQuantity || ''"
            :error="!masterQuantityValidation.isValid"
            :errorMessage="masterQuantityValidation.message"
            :skipDirtyCheck="skipDirtyCheck"
            @onChange="masterQuantityChange"
          />
          <NavigationPage
            :next-to="nextTo"
            :prev-to="prevTo"
            @on-next-to="onNextTo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationPage from "@/components/NavigationPage.vue";
import TextInput from "@/components/TextInput";
import { SHB, RMB } from "@/library/constants";

export default {
  name: "MasterQuantity",
  components: {
    NavigationPage,
    TextInput
  },
  data() {
    return {
      skipDirtyCheck: false
    };
  },
  computed: {
    ...mapGetters(["tempdata"]),
    title() {
      return (
        "How many master are there from the " +
        this.tempdata.banner.quantity +
        " banners?"
      );
    },
    prevTo() {
      return "banner-status";
    },
    nextTo() {
      var rn = "";
      if (
        this.tempdata.banner.status.id &&
        this.masterQuantityValidation.isValid
      ) {
        if (
          this.tempdata.banner.type.id === SHB ||
          this.tempdata.banner.type.id === RMB
        ) {
          rn = "banner-storyboard";
        } else {
          rn = "result";
        }
      }
      return rn;
    },
    masterQuantity: {
      set(value) {
        this.$store.dispatch("setMasterBannerQuantity", value);
      },
      get() {
        return this.tempdata.banner.master_quantity;
      }
    },
    masterQuantityValidation() {
      if (!this.masterQuantity || this.masterQuantity === {}) {
        return {
          isValid: false,
          message: "Master quantity is required"
        };
      }

      if (typeof this.masterQuantity !== "number") {
        return {
          isValid: false,
          message: "Must be a valid number format"
        };
      }

      if (Number(this.masterQuantity) < 1) {
        return {
          isValid: false,
          message: "Must be at least 1 banner"
        };
      }

      if (Number(this.masterQuantity) > Number(this.tempdata.banner.quantity)) {
        return {
          isValid: false,
          message: `Must be less than or equal to (${this.tempdata.banner.quantity}) banners`
        };
      }

      if (!Number.isInteger(Number(this.masterQuantity))) {
        return {
          isValid: false,
          message: "Must be an integer value"
        };
      }

      return {
        isValid: true,
        message: ""
      };
    }
  },
  methods: {
    masterQuantityChange(value) {
      this.masterQuantity = Number(value);
    },
    onNextTo() {
      if (!this.nextTo) {
        this.skipDirtyCheck = true;
      } else {
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      }
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 1);
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.banner.status.id) {
        next();
      } else {
        next({ name: "banner-status" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
