import axios from "axios";

export async function createWrikeAttachment(file, taskId) {
  // debugger;
  let formData = new FormData();

  formData.append("file", file);

  const TOKEN = process.env.VUE_APP_WRIKE_TOKEN;
  const API_URI = `https://www.wrike.com/api/v4/tasks/${taskId}/attachments`;
  const res = await axios({
    url: API_URI,
    method: "post",
    data: formData,
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      "Content-Type": "multipart/form-data",
      "X-File-Name": file.name
    }
  });
  return await res;
}
