<template>
  <div class="page page--web-video-config">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="title"></h1>
          <div class="row">
            <div :class="durationClass">
              <SelectDropdown
                :placeholder="video.totalDurationOptions.placeholder"
                :label="video.totalDurationOptions.label"
                :value="tempdata.video.duration"
                :options="video.totalDurationOptions.list"
                :error="!videoDurationValidation.isValid"
                :errorMessage="videoDurationValidation.message"
                :skipDirtyCheck="skipDirtyCheck"
                @onChange="durationChange"
              />
              <p v-html="video.totalDurationOptions.body"></p>
            </div>
            <div v-if="videoTypeIsVector" class="form-group col-lg-6">
              <SelectDropdown
                :placeholder="video.isWithAssetsOptions.placeholder"
                :label="optionNotification"
                :value="tempdata.video.isWithAssets"
                :options="video.isWithAssetsOptions.list"
                :error="!isWithAssetsValidation.isValid"
                :errorMessage="isWithAssetsValidation.message"
                :skipDirtyCheck="skipDirtyCheck"
                @onChange="isWithAssetsChange"
              />
              <p v-html="video.isWithAssetsOptions.body"></p>
            </div>
          </div>
          <NavigationPage
            :next-to="nextTo"
            :prev-to="prevTo"
            @on-next-to="onNextTo"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NavigationPage from "@/components/NavigationPage.vue";
import SelectDropdown from "@/components/SelectDropdown";
import { VECVIDEO } from "@/library/constants";

export default {
  name: "VideoDuration",
  components: {
    NavigationPage,
    SelectDropdown
  },
  data() {
    return {
      skipDirtyCheck: false,
      optionNotification: ""
    };
  },
  computed: {
    ...mapGetters(["tempdata", "video", "videoTypeIsVector"]),
    title() {
      return this.videoTypeIsVector
        ? this.video.vidoConfigPage.title
        : this.video.totalDurationOptions.title;
    },
    durationClass() {
      return ["form-group", this.videoTypeIsVector ? "col-lg-6" : "col-12"];
    },
    prevTo() {
      return this.videoTypeIsVector ? "vector-video-type" : "video-type";
    },
    nextTo() {
      return this.videoDurationValidation.isValid &&
        this.isWithAssetsValidation.isValid
        ? "result"
        : null;
    },
    selectedDuration: {
      set(value) {
        this.$store.dispatch("setVideoDuration", value);
      },
      get() {
        return this.tempdata.video.duration;
      }
    },
    videoDurationValidation() {
      if (!this.selectedDuration) {
        return {
          isValid: false,
          message: "Video durations is required"
        };
      }
      return {
        isValid: true,
        message: ""
      };
    },
    isWithAssets: {
      set(value) {
        this.$store.dispatch("setIsVideoAssets", value);
      },
      get() {
        return this.tempdata.video.isWithAssets;
      }
    },
    isWithAssetsValidation() {
      if (!this.isWithAssets && this.videoTypeIsVector) {
        return {
          isValid: false,
          message: "Please answer this question"
        };
      }
      return {
        isValid: true,
        message: ""
      };
    }
  },
  methods: {
    durationChange(value) {
      this.selectedDuration = value;
    },
    isWithAssetsChange(value) {
      this.isWithAssets = value;
      this.optionNotification = this.isWithAssets.fixNotification;
    },
    validateNow() {
      this.skipDirtyCheck = true;
    },
    onNextTo() {
      if (!this.nextTo) {
        this.validateNow();
      } else {
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      }
    }
  },
  mounted() {
    if (this.videoTypeIsVector) {
      this.$store.dispatch("setGrid", 2);
    } else {
      this.$store.dispatch("setGrid", 1);
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.video.type) {
        if (tempdata.video.type.id === VECVIDEO) {
          next({ name: "vector-video-type" });
        } else {
          next();
        }
      } else {
        next({ name: "video-type" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
