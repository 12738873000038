<template>
  <div id="pdfarea" class="page page--result font-primary--light">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 result__left">
          <div class="pr-2">
            <h1 v-html="banner.result.title"></h1>

            <h2 class="total-cost">{{ totalCost }}<span>AUD</span></h2>

            <button :class="classButtonTask" @click.prevent="onAccept">
              <span class="caret">
                {{
                  isCtaRequest
                    ? "REQUEST HAS BEEN SUBMITTED"
                    : "ACCEPT ESTIMATE AND PROCEED"
                }}
                <span class="icon" v-if="!isCtaRequest"></span>
              </span>
            </button>

            <button :class="classButtonPdf" @click.prevent="onCreatePdf">
              <span class="caret">
                DOWNLOAD ESTIMATE AS PDF
                <span class="icon"></span>
              </span>
            </button>
            <div @click.prevent="onDiscard" class="card__summary hidden-pdf">
              <a herf="javascript:;"
                >Discard, I would like to create a new estimate&nbsp;&nbsp;></a
              >
            </div>
          </div>
        </div>

        <div
          :class="
            `col-12 col-md-8 result__right ${isShow ? 'show-breakdown' : ''}`
          "
        >
          <div class="result__toggle">
            <h2 class="font-primary--bold">
              Detailed Breakdown
            </h2>
          </div>
          <ResultBreakDown :isShow="isShow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import ResultBreakDown from "@/components/ResultBreakDown";

export default {
  name: "BannerResult",
  components: {
    ResultBreakDown
  },
  data() {
    return {
      isShow: true,
      isCtaRequest: false
    };
  },
  computed: {
    ...mapGetters(["banner", "tempdata", "cost", "breakdown"]),
    totalCost() {
      return this.cost
        ? Math.round(this.cost)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        : 0;
    },
    classButtonTask() {
      return "btn btn-primary hidden-pdf";
    },
    classButtonPdf() {
      return ["btn btn-primary hidden-pdf mt-4"];
    },
    taskDescription() {
      var dueDate = "-";
      if (this.tempdata.due.date) {
        dueDate = `${DateTime.fromISO(this.tempdata.due.date).toFormat(
          "dd LLL yyyy"
        )}`;
      }

      let d = new Date();
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ];
      const formattedDate = `${d.getDate()} ${
        months[d.getMonth()]
      } ${d.getFullYear()}`;

      const hours = d.getHours();
      let minutes = d.getMinutes();
      const formattedMinutes = ("0" + minutes).slice(-2);
      const currentTime = `${hours}:${formattedMinutes}`;

      const downloadURLs = this.tempdata.detail.downloadURLs;

      var des = "";
      for (var a of this.breakdown) {
        des += `<h5>${a.label}</h5>`;
        des += `<p>${a.value}</p>`;
        des += `<br>`;
      }
      des += `<h5>Total Cost</h5>`;
      des += `<p>${this.totalCost} AUD</p>`;
      des += `<h5>User Email</h5>`;
      des += `<p>${this.tempdata.user.email}</p>`;
      des += `<h5>Due on</h5>`;
      des += `<p>${dueDate}</p>`;
      des += `<h5>Download URLs</h5>`;
      des += `<p>${downloadURLs}</p>`;
      des += `<h5>Created on</h5>`;
      des += `<p>${formattedDate} ${currentTime}</p>`;
      return des;
    },
    taskTitle() {
      // let d = new Date();
      // const months = [
      //   "Jan",
      //   "Feb",
      //   "Mar",
      //   "Apr",
      //   "May",
      //   "June",
      //   "July",
      //   "Aug",
      //   "Sept",
      //   "Oct",
      //   "Nov",
      //   "Dec"
      // ];

      // const formattedDate = `${d.getDate()}-${
      //   months[d.getMonth()]
      // }-${d.getFullYear()}`;

      // const hours = d.getHours();
      // let minutes = d.getMinutes();
      // const formattedMinutes = ("0" + minutes).slice(-2);
      // const currentTime = `${hours}:${formattedMinutes}`;

      const productTitle = this.tempdata.product.title;
      const jobNumber = this.tempdata.detail.jobNumber;
      // const userEmail = this.tempdata.user.email;
      // const taskTitle = `${jobNumber} - ${productTitle} - ${formattedDate} ${currentTime} - ${userEmail}`;
      const taskTitle = `${jobNumber} - ${productTitle}`;

      return taskTitle;
    },
    classJobNumber() {
      return [
        "form-control textarea",
        this.isJobNumberRequired ? "is-required" : ""
      ];
    },
    placeholderJobNumber() {
      return [this.isJobNumberRequired ? "this field is required" : ""];
    },
    jobNumber: {
      set(jobNumber) {
        const downloadURLs = this.tempdata.detail.downloadURLs;
        this.$store.dispatch("setDetail", {
          jobNumber: jobNumber,
          downloadURLs: downloadURLs
        });
      },
      get() {
        return this.tempdata.detail.jobNumber;
      }
    },
    downloadURLs: {
      set(downloadURLs) {
        const jobNumber = this.tempdata.detail.jobNumber;
        this.$store.dispatch("setDetail", {
          jobNumber: jobNumber,
          downloadURLs: downloadURLs
        });
      },
      get() {
        return this.tempdata.detail.downloadURLs;
      }
    }
  },
  methods: {
    onDiscard() {
      this.$store.dispatch("resetState");
      localStorage.removeItem("tempdata");
      this.$router.push({ name: "HomeEstimator" });
    },
    onCreatePdf() {
      window.print();
    },
    onAccept() {
      this.$router.push({ name: "ResultDetail" });
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 2);
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.product.id) {
        next();
      } else {
        next({ name: "HomeEstimator" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
