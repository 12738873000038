export default {
  setBannerState: (state, payload) => {
    state.banner = payload;
  },
  addBannerState: (state, payload) => {
    state.banner = { ...state.banner, ...payload };
  },
  setBannerType: (state, payload) => {
    state.tempdata.banner.type = payload;
  },
  setBannerQuantity: (state, payload) => {
    state.tempdata.banner.quantity = payload;
  },
  setBannerFrame: (state, payload) => {
    state.tempdata.banner.frame = payload;
  },
  setBannerStatus: (state, payload) => {
    state.tempdata.banner.status = payload;
  },
  setMasterBannerQuantity: (state, payload) => {
    state.tempdata.banner.master_quantity = payload;
  },
  setBannerVariation: (state, payload) => {
    state.tempdata.banner.variation = payload;
  },
  setBannerStoryBoard: (state, payload) => {
    state.tempdata.banner.storyboard = payload;
  },
  setBannerPsd: (state, payload) => {
    state.tempdata.banner.psd = payload;
  }
};
