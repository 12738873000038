var totalHours = 0;
var totalCost = 0;
export default {
  video: state => {
    return state.video;
  },
  videoTotalCost: (state, getters) => {
    try {
      const base = Number(state.tempdata.video.type.hour);
      const videoDuration = Number(state.tempdata.video.duration.timeInSeconds);
      const durationMultiplier = Number(state.tempdata.video.duration.point);
      const baseHours = base * durationMultiplier;
      var assetsAdditionalHour = 0;
      var cost = 0;

      if (getters.videoTypeIsVector) {
        let assetsBase = Number(state.tempdata.video.isWithAssets.hour);
        let assetsMultiplyEvery = Number(
          state.tempdata.video.isWithAssets.multiplyEvery
        );
        assetsAdditionalHour =
          (assetsBase * (videoDuration / assetsMultiplyEvery)) | 0;
      }
      const totalHour = baseHours + assetsAdditionalHour;
      const basePrice = Number(state.tempdata.product.price.value);
      const estimate = Number(state.tempdata.product.estimate);

      totalHours = totalHour;
      // totalHours = round(totalHours, 0.05);

      cost = totalHours * estimate * basePrice;

      totalCost = cost;
      return cost;
    } catch {
      return 0;
    }
  },
  videoBreakdown: (state, getters) => {
    const basePrice = Number(state.tempdata.product.price.value);
    const estimate = Number(state.tempdata.product.estimate);
    try {
      const breakdown = [
        {
          label: state.video.type.label,
          value: state.tempdata.video.type.title
        },
        {
          label: state.video.totalDurationOptions.label,
          value: state.tempdata.video.duration.label
        }
      ];
      if (getters.videoTypeIsVector) {
        breakdown.push({
          label: state.video.isWithAssetsOptions.label,
          value: state.tempdata.video.isWithAssets.label
        });
      }

      // var totalHoursAnimator = Math.round(
      //   totalHours * state.video.animator.value
      // )
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // var totalHoursQA = Math.round(totalHours * state.video.QA.value)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // var totalHoursAD = Math.round(totalHours * state.video.AD.value)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // totalHoursAnimator = totalHoursAnimator > 0 ? totalHoursAnimator : 0.5;
      // totalHoursQA = totalHoursQA > 0 ? totalHoursQA : 0.5;
      // totalHoursAD = totalHoursAD > 0 ? totalHoursAD : 0.5;

      totalCost = round(totalCost, 1);
      totalHours = totalCost / estimate / basePrice;

      var totalHoursAnimator =
        ((totalHours * state.video.animator.value).toFixed(2) * 100) / 100;

      var totalHoursQA =
        ((totalHours * state.video.QA.value).toFixed(2) * 100) / 100;

      var totalHoursAD =
        ((totalHours * state.video.AD.value).toFixed(2) * 100) / 100;

      // totalHoursAnimator = totalHoursAnimator > 0 ? totalHoursAnimator : 0.5;
      // totalHoursQA = totalHoursQA > 0 ? totalHoursQA : 0.5;
      // totalHoursAD = totalHoursAD > 0 ? totalHoursAD : 0.5;

      totalHoursAnimator = round(totalHoursAnimator, 0.5);
      totalHoursQA = round(totalHoursQA, 0.5);
      totalHoursAD = round(totalHoursAD, 0.5);

      breakdown.push({
        label: state.video.animator.label,
        value: totalHoursAnimator + " Hours"
      });

      breakdown.push({
        label: state.video.QA.label,
        value: totalHoursQA + " Hours"
      });

      breakdown.push({
        label: state.video.AD.label,
        value: totalHoursAD + " Hours"
      });

      return breakdown;
    } catch {
      return [];
    }
  },
  videoTypeIsVector: state => {
    try {
      const currentVideoId = state.tempdata.video.type.id;
      const findVector = state.video.vectorVariations.list.filter(
        vector => vector.id === currentVideoId
      );
      return findVector.length > 0;
    } catch {
      return false;
    }
  }
};

function round(value, step) {
  step || (step = 1.0);
  var inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}
