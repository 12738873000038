import Home from "@/views/Home";
import Product from "@/views/Product";

import BannerType from "@/views/banner/Type";
import BannerQuantity from "@/views/banner/Quantity";
import BannerStatus from "@/views/banner/Status";
import BannerMasterQuantity from "@/views/banner/MasterQuantity";
import BannerVariation from "@/views/banner/Variation";
import BannerStoryBoard from "@/views/banner/StoryBoard";
import BannerPsd from "@/views/banner/Psd";

import EdmType from "@/views/edm/Type";
import EdmStatus from "@/views/edm/Status";
import EdmDesign from "@/views/edm/Design";

import VideoType from "@/views/video/VideoType";
import VectorVideoType from "@/views/video/VectorVideoType";
import VideoConfig from "@/views/video/VideoConfig";

import Result from "@/views/Result";
import ResultDetail from "@/views/ResultDetail";

export default [
  {
    path: "/",
    name: "HomeEstimator",
    components: {
      main: Home
    }
  },
  {
    path: "/product",
    name: "Product",
    components: {
      main: Product
    }
  },
  // Banner
  {
    path: "banner-type",
    name: "banner-type",
    components: {
      main: BannerType
    }
  },
  {
    path: "banner-quantity",
    name: "banner-quantity",
    components: {
      main: BannerQuantity
    }
  },
  {
    path: "banner-status",
    name: "banner-status",
    components: {
      main: BannerStatus
    }
  },
  {
    path: "banner-psd",
    name: "banner-psd",
    components: {
      main: BannerPsd
    }
  },
  {
    path: "banner-master-quantity",
    name: "banner-master-quantity",
    components: {
      main: BannerMasterQuantity
    }
  },
  {
    path: "banner-variation",
    name: "banner-variation",
    components: {
      main: BannerVariation
    }
  },
  {
    path: "banner-storyboard",
    name: "banner-storyboard",
    components: {
      main: BannerStoryBoard
    }
  },
  // EDM
  {
    path: "edm-type",
    name: "edm-type",
    components: {
      main: EdmType
    }
  },
  {
    path: "edm-status",
    name: "edm-status",
    components: {
      main: EdmStatus
    }
  },
  {
    path: "edm-design",
    name: "edm-design",
    components: {
      main: EdmDesign
    }
  },
  // Video
  {
    path: "video-type",
    name: "video-type",
    components: {
      main: VideoType
    }
  },
  {
    path: "vector-video-type",
    name: "vector-video-type",
    components: {
      main: VectorVideoType
    }
  },
  {
    path: "video-config",
    name: "video-config",
    components: {
      main: VideoConfig
    }
  },
  {
    path: "result",
    name: "result",
    components: {
      main: Result
    }
  },
  {
    path: "result-detail",
    name: "ResultDetail",
    components: {
      main: ResultDetail
    }
  }
];
