<template>
  <div class="page page--banner-variation">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1
            class="font-primary--light"
            v-html="tempdata.banner.status.title"
          ></h1>
          <p>
            {{ tempdata.banner.status.subtitle }}
          </p>
          <card-button
            :data="tempdata.banner.status.list"
            @selectcard="selectBannerVariation"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage.vue";
import { SHB, RMB } from "@/library/constants";

export default {
  name: "BannerVariation",
  components: {
    NavigationPage,
    CardButton
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["banner", "tempdata"]),
    prevTo() {
      return "banner-status";
    },
    nextTo() {
      var rn = "";
      if (
        this.tempdata.banner.status &&
        this.tempdata.banner.status.id &&
        this.tempdata.banner.variation &&
        this.tempdata.banner.variation.id
      ) {
        if (
          this.tempdata.banner.type.id === SHB ||
          this.tempdata.banner.type.id === RMB
        ) {
          rn = "banner-storyboard";
        } else {
          rn = "result";
        }
      }
      return rn;
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 3);
  },
  methods: {
    selectBannerVariation(val) {
      this.$store.dispatch("setBannerVariation", val);
      this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      this.$router.push({ name: this.nextTo });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.banner.status && tempdata.banner.status.id) {
        next();
      } else {
        next({ name: "banner-status" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
