import { CCG, DESYES } from "@/library/constants";

var totalHours = 0;
var totalCost = 0;

export default {
  edm: state => {
    return state.edm;
  },
  edmTotalCost: state => {
    try {
      const n = Number;
      const type = state.tempdata.edm.type.id;
      const edmEstimate = n(state.tempdata.edm.type.estimate);
      const productPrice = n(state.tempdata.product.price.value);
      const edmTypeHour = n(state.tempdata.edm.type.hour);
      var fixedCost = 22.5;
      var edmDesignHour = n(state.edm.design.list[1].hour);
      var cost = 0;
      if (type === CCG) {
        if (state.tempdata.edm.design.id === DESYES) {
          edmDesignHour = n(
            state.tempdata.edm.design.list[
              state.tempdata.edm.type.status_index_reference
            ].hour
          );
        }
        totalHours = edmTypeHour + edmDesignHour;
      } else {
        fixedCost = 0;
        if (state.tempdata.edm.design.id === DESYES) {
          edmDesignHour = n(
            state.tempdata.edm.design.list[
              state.tempdata.edm.type.status_index_reference
            ].list[state.tempdata.edm.status.design_index_reference].hour
          );
        }

        let edmStatusPoint = n(state.tempdata.edm.status.point);

        totalHours = edmTypeHour * edmStatusPoint + edmDesignHour;
      }

      // totalHours = round(totalHours, 0.05);
      cost = totalHours * edmEstimate * productPrice + fixedCost;

      totalCost = cost;
      return cost;
    } catch (e) {
      return e;
    }
  },
  edmBreakdown: state => {
    try {
      const n = Number;
      const type = state.tempdata.edm.type.id;
      const edmEstimate = n(state.tempdata.edm.type.estimate);
      const productPrice = n(state.tempdata.product.price.value);
      var breakdown = [
        {
          label: state.products.label,
          value: state.tempdata.product.title
        },
        {
          label: state.edm.type.label,
          value: state.tempdata.edm.type.title
        }
      ];
      if (type !== CCG) {
        breakdown.push({
          label: state.edm.status.label,
          value: state.tempdata.edm.status.title
        });
      }
      breakdown.push({
        label: state.edm.design.label,
        value: state.tempdata.edm.design.title
      });

      // var totalHoursDeveloper = Math.round(
      //   totalHours * state.edm.developer.value
      // )
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // var totalHoursQA = Math.round(totalHours * state.edm.QA.value)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // var totalHoursTO = Math.round(totalHours * state.edm.TO.value)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // totalHoursDeveloper = totalHoursDeveloper > 0 ? totalHoursDeveloper : 0.5;
      // totalHoursQA = totalHoursQA > 0 ? totalHoursQA : 0.5;
      // totalHoursTO = totalHoursTO > 0 ? totalHoursTO : 0.5;

      totalCost = round(totalCost, 1);
      var fixedCost = 22.5;

      if (type === CCG) {
        fixedCost = 0;
        totalHours = (totalCost - fixedCost) / edmEstimate / productPrice;
      } else {
        fixedCost = 0;
        totalHours = (totalCost - fixedCost) / edmEstimate / productPrice;
      }

      var totalHoursDeveloper =
        ((totalHours * state.edm.developer.value).toFixed(2) * 100) / 100;

      var totalHoursQA =
        ((totalHours * state.edm.QA.value).toFixed(2) * 100) / 100;

      var totalHoursTO =
        ((totalHours * state.edm.TO.value).toFixed(2) * 100) / 100;

      // totalHoursDeveloper = totalCost / edmEstimate / productPrice;

      // totalHoursDeveloper = totalHoursDeveloper > 0 ? totalHoursDeveloper : 0.5;
      // totalHoursQA = totalHoursQA > 0 ? totalHoursQA : 0.5;
      // totalHoursTO = totalHoursTO > 0 ? totalHoursTO : 0.5;

      totalHoursDeveloper = round(totalHoursDeveloper, 0.5);
      totalHoursQA = round(totalHoursQA, 0.5);
      totalHoursTO = round(totalHoursTO, 0.5);

      breakdown.push({
        label: state.edm.developer.label,
        value: totalHoursDeveloper + " Hours"
      });

      breakdown.push({
        label: state.edm.QA.label,
        value: totalHoursQA + " Hours"
      });

      breakdown.push({
        label: state.edm.TO.label,
        value: totalHoursTO + " Hours"
      });

      return breakdown;
    } catch {
      return [];
    }
  }
};

function round(value, step) {
  step || (step = 1.0);
  var inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}
