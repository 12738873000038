<template>
  <div class="input-text-wrapper">
    <div :class="inputClassWrap">
      <input
        :class="inputStyle"
        :type="type"
        :placeholder="placeholder"
        :value="localValue"
        :min="type === 'number' ? 1 : null"
        @input="onInputChange"
        @keydown="onKeyDown"
        @focus="isFocus = true"
        @blur="isFocus = false"
        autocomplete="on"
        :name="name"
      />
      <div class="number-trigger">
        <input
          type="button"
          value="+"
          @click="changeValInputNumber('plus')"
          v-if="type === 'number'"
          class="number-trigger-arrow plus-val"
        />
        <input
          type="button"
          value="-"
          @click="changeValInputNumber('min')"
          v-if="type === 'number'"
          :class="
            `number-trigger-arrow min-val ${localValue > 1 ? '' : 'disabled'}`
          "
        />
      </div>
    </div>
    <label v-if="showError" :class="labelErrorStyle">
      {{ errorMsg }}
    </label>
    <label v-else :class="labelStyle">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  data() {
    return {
      localValue: "",
      isDirty: false,
      isError: this.error,
      errorMsg: this.errorMessage,
      isFocus: false
    };
  },
  props: {
    classWrap: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: [Number, String],
      default: 0 | ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    skipDirtyCheck: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: "text"
    }
  },
  computed: {
    showLabel() {
      return this.localValue > 0;
    },
    inputStyle() {
      return [
        "form-control",
        "input-text",
        { active: this.showLabel && !this.showError },
        { error: this.showError }
      ];
    },
    labelStyle() {
      let classes = [
        "input-label",
        "font-primary--light",
        "animated",
        "faster"
      ];
      if (this.showLabel && !this.showError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    labelErrorStyle() {
      let classes = [
        "input-label",
        "font-primary--light",
        "animated",
        "faster",
        "error"
      ];
      if (this.showError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    inputClassWrap() {
      return [
        `input-${this.type}`,
        this.classWrap,
        {
          active:
            (this.showLabel && !this.showError) ||
            (this.isFocus && !this.showError)
        },
        { error: this.showError }
      ];
    },
    showError() {
      return (
        (this.skipDirtyCheck && this.isError) || (this.isError && this.isDirty)
      );
    }
  },
  methods: {
    onInputChange(e) {
      this.isDirty = true;
      this.localValue = e.target.value;
      this.$emit("onChange", e.target.value);
    },
    onKeyDown(e) {
      if (this.type === "number") {
        let invalidChars = ["-", "+", "e"];
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      }
    },
    // onKeyUp() {
    //   if (this.type === "email" && this.localValue) {
    //     let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     this.isError = !re.test(String(this.localValue).toLowerCase());
    //     if (this.isError) {
    //       this.errorMsg = "Invalid email address.";
    //     }

    //     let allowed = ["ogilvy.com.au", "wppanuz.com"];
    //     if (this.localValue.includes("@")) {
    //       let str = this.localValue.split("@").slice(1);
    //       if (allowed.indexOf(str[0]) == -1) {
    //         this.errorMsg = "Email address is not allowed.";
    //       }
    //     }
    //   }
    // },
    changeValInputNumber(action) {
      if (this.localValue > 1 && action === "min") {
        this.isDirty = true;
        this.localValue--;
      }
      if (action === "plus") {
        this.isDirty = true;
        this.localValue++;
      }
    }
  },
  mounted() {
    this.localValue = this.value;
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    error(newVal) {
      this.isError = newVal;
    },
    errorMessage(newVal) {
      this.errorMsg = newVal;
    },
    localValue(newVal) {
      this.$emit("onChange", newVal);
    }
  }
};
</script>
