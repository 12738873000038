<template>
  <div :class="`modal ${isModalShow ? 'show' : ''}`">
    <div class="container">
      <div class="row">
        <a
          href="javascript:;"
          class="modal__close"
          aria-label="close modal"
          @click="close()"
        ></a>
        <div :class="`col-12  modal__body ${hasMedia ? 'col-md-6' : ''}`">
          <div class="modal__title">
            <h4 v-if="modalData.title">
              {{ modalData.title }}
            </h4>
          </div>
          <p v-if="modalData.body" v-html="modalData.body"></p>

          <div v-if="modalData.notIncluded" class="mt-5">
            <div class="modal__title">
              <h4>
                WHAT’S NOT INCLUDED
              </h4>
            </div>
            <p v-if="modalData.notIncluded" v-html="modalData.notIncluded"></p>
          </div>
        </div>
        <div class="col-12 col-md-6 modal__content" v-if="hasMedia">
          <media :media="modalData.media" v-if="hasMedia"></media>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Media from "./Media";
export default {
  name: "Modal",
  data() {
    return {
      previousGrid: 1
    };
  },
  components: {
    Media
  },
  computed: {
    ...mapGetters(["modal"]),
    isModalShow() {
      const isModalShow = this.modal && this.modal.status;
      isModalShow ? this.setGrid() : this.close();

      return isModalShow;
    },
    modalData() {
      return this.modal && this.modal.data && this.modal.data.modal
        ? this.modal.data.modal
        : "";
    },
    hasMedia() {
      return this.modalData.media && this.modalData.media.type !== "";
    }
  },
  mounted() {
    this.escListener();
    this.previousGrid = this.$store.state.grid;
  },
  watch: {
    $route() {
      this.close();
    }
  },
  methods: {
    close() {
      this.$store.dispatch("toggleModal", false);
      this.$store.dispatch("setGrid", this.previousGrid);
    },
    setGrid() {
      this.hasMedia
        ? this.$store.dispatch("setGrid", 2)
        : this.$store.dispatch("setGrid", 1);
    },
    escListener() {
      const self = this;
      document.onkeydown = function(evt) {
        evt = evt || window.event;
        let isEscape = false;

        if ("key" in evt) {
          isEscape = evt.key === "Escape" || evt.key === "Esc";
        } else {
          isEscape = evt.keyCode === 27;
        }

        if (isEscape) {
          self.close();
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
