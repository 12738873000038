import Vue from "vue";
import Router from "vue-router";
import Main from "./views/Main.vue";
import Email from "@/views/Email";
import NotFound from "@/views/NotFound";
import PricingTool from "./router/pricing-tool";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Main,
      children: PricingTool
    },
    {
      path: "/production-request",
      name: "edm-template",
      component: Email
    },
    {
      path: "/**",
      component: Main,
      children: [
        {
          path: "/",
          name: "404",
          components: { main: NotFound }
        }
      ]
    }
  ]
});
