export const WEB_BANNER = "WEB_BANNER";
export const EDM = "EDM";
export const VIDEO = "VIDEO";
export const SHB = "STANDARD_HTML5_BANNER";
export const RMB = "RICH_MEDIA_BANNER";
export const AGB = "ANIMATED_GIF_BANNER";
export const STC = "STATIC_BANNER";
export const NEW = "NEW_BANNER";
export const EXIST = "EXISTING_BANNER";
export const SCH = "SINGLE_COLUMN_HTML";
export const MCH = "MULTIPLE_COLUMN_HTML";
export const CCG = "CONTENT_CHANGE";
export const NSS = "NEW_STYLE_SINGLE";
export const ESS = "EXISTING_STYLE_SINGLE";
export const NSM = "NEW_STYLE_MULTI";
export const ESM = "EXISTING_STYLE_MULTI";
export const DESYES = "DESIGN_YES";
export const DESNO = "DESIGN_NO";
export const VECVIDEO = "VECTOR_VIDEO";
