<template>
  <div class="page page--web-banner-type">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="edm.type.title"></h1>
          <card-button
            :data="edm.type.list"
            @selectcard="selectEdmType"
            :default-selected="tempdata.edm.type"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage";
import { SCH, MCH, CCG } from "@/library/constants";

export default {
  name: "Type",
  components: {
    CardButton,
    NavigationPage
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters(["edm", "tempdata"]),
    edmType() {
      return (
        this.tempdata.edm.type &&
        this.tempdata.edm.type.id &&
        this.tempdata.edm.type.id
      );
    },
    prevTo() {
      return "Product";
    },
    nextTo() {
      var rn;
      if (this.edmType === SCH || this.edmType === MCH) {
        rn = "edm-status";
      } else if (this.edmType === CCG) {
        rn = "edm-design";
      } else {
        rn = "";
      }
      return rn;
    }
  },
  methods: {
    selectEdmType(val) {
      if (val.published) {
        this.$store.dispatch("setEdmType", val);
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
        this.$router.push({ name: val.router_name });
      }
    }
  },
  created() {
    this.$store.dispatch("setGrid", 3);
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.product && tempdata.product.id) {
        next();
      } else {
        next({ name: "Product" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
