<template>
  <div class="page page--banner-storyboard">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="banner.storyboard.title"></h1>
          <p>
            {{ banner.storyboard.subtitle }}
          </p>

          <card-button
            :data="banner.storyboard.list"
            @selectcard="selectStoryBoard"
            :default-selected="tempdata.banner.storyboard"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage";
import { NEW, EXIST, AGB, STC } from "@/library/constants";

export default {
  name: "BannerStoryBoard",
  components: {
    CardButton,
    NavigationPage
  },
  data() {
    return {
      index: 0
    };
  },
  computed: {
    ...mapGetters(["banner", "tempdata"]),
    bannerStatus() {
      return this.tempdata.banner.status && this.tempdata.banner.status.id
        ? this.tempdata.banner.status.id
        : "";
    },
    prevTo() {
      var rn = "";
      if (this.bannerStatus === NEW) {
        rn = "banner-master-quantity";
      } else if (this.bannerStatus === EXIST) {
        rn = "banner-variation";
      }
      return rn;
    },
    nextTo() {
      return (
        this.tempdata.banner.storyboard &&
        this.tempdata.banner.storyboard.id &&
        "result"
      );
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 2);
  },
  methods: {
    selectStoryBoard(val) {
      this.$store.dispatch("setBannerStoryBoard", val);
      this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      this.$router.push({ name: val.router_name });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (
        tempdata.product !== AGB &&
        tempdata.product !== STC &&
        tempdata.banner.status
      ) {
        if (tempdata.banner.status.id === NEW) {
          if (tempdata.banner.master_quantity > 0) {
            next();
          } else {
            next({ name: "banner-master-quantity" });
          }
        } else {
          if (tempdata.banner.variation && tempdata.banner.variation.id) {
            next();
          } else {
            next({ name: "banner-variation" });
          }
        }
      } else {
        next({ name: "banner-status" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
