<template>
  <div class="page page--not-found">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="font-primary--bold">
            <strong>Page Not Found</strong>
          </h2>
          <p>
            Sorry, the page you have requested has either been moved or does not
            exist. <br />You can go back and explore other pages.
          </p>
          <p>
            <button class="btn btn-primary" @click.prevent="backToHome">
              <span class="caret">Bck To Home<span class="icon"></span></span>
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  mounted() {
    this.$store.dispatch("setGrid", 1);
  },
  methods: {
    backToHome() {
      this.$router.push({ name: "HomeEstimator" });
    }
  }
};
</script>
