import { cloneDeep } from "lodash";
import product from "./mutations/product";
import banner from "./mutations/banner";
import video from "./mutations/video";
import edm from "./mutations/edm";
import allowedEmails from "./mutations/allowedEmails";
import tempdata from "@/store/state/tempdata";

export default {
  ...product,
  ...banner,
  ...edm,
  ...video,
  ...allowedEmails,
  setTempData: (state, payload) => {
    state.tempdata = payload;
  },
  setDetail: (state, payload) => {
    state.tempdata.detail = payload;
  },
  setUser: (state, payload) => {
    state.tempdata.user = payload;
  },
  setStart: (state, payload) => {
    state.tempdata.start = payload;
  },
  setDue: (state, payload) => {
    state.tempdata.due = payload;
  },
  setGrid: (state, payload) => {
    state.grid = payload;
  },
  setModal: (state, payload) => {
    state.modal = payload;
  },
  toggleModal: (state, payload) => {
    state.modal.status = payload;
  },
  addTempDataToLocalStorage: (state, payload) => {
    localStorage.tempdata = JSON.stringify(payload);
  },
  resetTempData: state => {
    state.tempdata = cloneDeep(tempdata);
  },
  setQuoteID: (state, payload) => {
    state.quoteID = payload;
  }
};
