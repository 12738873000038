<template>
  <div class="page page--banner-storyboard">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="banner.psd.title"></h1>
          <card-button
            :data="banner.psd.list"
            @selectcard="selectPsd"
            :default-selected="tempdata.banner.psd"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage";

export default {
  name: "BannerPsd",
  components: {
    CardButton,
    NavigationPage
  },
  data() {
    return {
      index: 0
    };
  },
  computed: {
    ...mapGetters(["banner", "tempdata"]),
    prevTo() {
      return "banner-status";
    },
    nextTo() {
      return (
        this.tempdata.banner.psd && this.tempdata.banner.psd.id && "result"
      );
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 2);
  },
  methods: {
    selectPsd(val) {
      this.$store.dispatch("setBannerPsd", val);
      this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      this.$router.push({ name: val.router_name });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.banner.status && tempdata.banner.status.id) {
        next();
      } else {
        next({ name: "banner-status" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
