<template>
  <div :class="classMediaType">
    <iframe
      :src="media.url"
      height="250"
      width="300"
      frameborder="0"
      v-if="media.type === 'BANNER'"
    ></iframe>
    <img
      :src="media.url"
      :alt="media.alt"
      :width="media.width ? media.width : '100%'"
      :height="media.height ? media.height : 'auto'"
      v-if="media.type === 'IMAGE'"
    />
  </div>
</template>

<script>
export default {
  name: "media",
  data() {
    return {};
  },
  props: {
    media: {
      type: Object,
      default: () => {
        return {
          type: "",
          url: "",
          alt: "",
          width: "100%",
          height: "auto"
        };
      }
    }
  },
  computed: {
    classMediaType() {
      return ["media", `media-${this.media.type.toLowerCase()}`];
    }
  }
};
</script>
