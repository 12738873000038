export default {
  setEdmState: (state, payload) => {
    state.edm = payload;
  },
  setEdmType: (state, payload) => {
    state.tempdata.edm.type = payload;
  },
  setEdmStatus: (state, payload) => {
    state.tempdata.edm.status = payload;
  },
  setEdmDesign: (state, payload) => {
    state.tempdata.edm.design = payload;
  }
};
