<template>
  <div class="header">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <a href="javascript:;" @click="onDiscard">
            <img
              class="logo--header"
              alt="Ogilvy Logo"
              :src="require('../assets/images/bullseye-logo.svg')"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    onDiscard() {
      this.$store.dispatch("resetState");
      localStorage.removeItem("tempdata");
      this.$router.push({ name: "HomeEstimator" });
    }
  }
};
</script>
