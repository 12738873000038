<template>
  <div class="upload-files font-primary--light">
    <div class="row">
      <div class="col-sm-12 col-md-5">
        <div
          class="file-wrap"
          v-bind:class="{ dragover: dragover }"
          v-on:drop="dropHandler"
          v-on:dragover="dragHandler"
        >
          <input type="file" ref="fileupload" multiple @change="selectFile" />
          <div class="drop-area">
            <img src="../assets/images/icon/upload.png" alt="" />
            <label><span>Drag Files to Upload</span></label>
          </div>
          <div class="button-area">
            <div class="mask-top">Or</div>
            <div class="mask-center">
              <button class="btn btn-primary">Browse Files</button>
            </div>
            <div class="mask-bottom"></div>
          </div>

          <button
            class="btn btn-primary"
            :disabled="!selectedFiles"
            @click="uploadFiles"
            style="display: none;"
          >
            Upload
          </button>
        </div>
        <span class="max-file-size">Max. file size 100MB</span>
      </div>
      <div class="col-sm-12 col-md-7">
        <div v-if="progressInfos" class="progress-infos">
          <div
            class="item"
            v-bind:class="{ 'has-error': !progressInfo.success }"
            v-for="(progressInfo, index) in progressInfos"
            :key="index"
          >
            <div class="file-name">
              <span v-bind:title="progressInfo.fileName">{{
                progressInfo.fileName
              }}</span>
              <button
                class="btn btn-delete"
                @click="deleteFile(progressInfo.fileName, index)"
              >
                Delete
              </button>
            </div>

            <div class="progress">
              <div
                class="progress-bar progress-bar-info"
                role="progressbar"
                :aria-valuenow="progressInfo.percentage"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="{ width: progressInfo.percentage + '%' }"
              >
                {{ progressInfo.percentage }}%
              </div>
            </div>
            <span class="progress-message">{{ progressInfo.message }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="message" class="alert alert-light" role="alert">
      <ul>
        <li v-for="(ms, i) in message.split('\n')" :key="i">
          {{ ms }}
        </li>
      </ul>
    </div>

    <div class="card">
      <div class="card-header">List of Files</div>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item"
          v-for="(file, index) in fileInfos"
          :key="index"
        >
          <a :href="file.url" target="_blank">{{ file.name }}</a>

          <button class="btn btn-delete" @click="deleteFile(file.name, index)">
            Delete
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "upload-files",
  data() {
    return {
      selectedFiles: undefined,
      progressInfos: [],
      message: "",
      quoteID: this.$store.state.quoteID,
      fileInfos: [],
      fileDatas: [],
      dragover: false,
      fileLoadedTime: []
    };
  },
  methods: {
    dragHandler(ev) {
      if (ev) {
        this.dragover = true;
      } else {
        this.dragover = false;
      }
    },
    dropHandler(ev) {
      if (ev) {
        this.dragover = false;
      } else {
        this.dragover = false;
      }
    },
    selectFile() {
      this.selectedFiles = event.target.files;
      this.uploadFiles();
    },
    uploadFiles() {
      this.message = "";
      for (let i = 0; i < this.selectedFiles.length; i++) {
        let file = this.selectedFiles[i];
        setTimeout(() => {
          let fileValidation = this.fileValidation(file);
          let ii = this.progressInfos.length;
          let event = {};
          let loaded = 0;
          let fileSize = file.size;
          fileSize = 100;

          this.progressInfos[ii] = {
            percentage: 0,
            loaded: loaded,
            total: fileSize,
            fileName: file.name,
            message: fileValidation.message,
            success: fileValidation.success
          };

          event.loaded = loaded;
          event.total = fileSize;
          this.progressInfos[ii].percentage = Math.round(
            (100 * event.loaded) / event.total
          );

          this.fileLoadedTime[ii] = setInterval(() => {
            this.fileLoaded(ii);
          }, 10);

          this.fileDatas.push({
            file: file,
            message: fileValidation.message,
            success: fileValidation.success
          });

          if (fileValidation.success) {
            this.fileInfos.push({
              name: file.name,
              url: ""
            });
          }

          if (i + 1 == this.selectedFiles.length) {
            this.time = setTimeout(() => {
              this.clear();
              this.$emit("onChange", this.fileDatas);
            }, 100);
          }
        }, i * 200);
      }
    },
    fileLoaded(ii) {
      if (this.progressInfos[ii].loaded >= this.progressInfos[ii].total) {
        clearInterval(this.fileLoadedTime[ii]);
      } else {
        let event = {};
        event.loaded = this.progressInfos[ii].loaded;
        event.total = this.progressInfos[ii].total;
        event.loaded = event.loaded + 20;
        this.progressInfos[ii].loaded = event.loaded;
        this.progressInfos[ii].percentage = Math.round(
          (100 * event.loaded) / event.total
        );
      }
    },
    deleteFile(fileName, index) {
      this.progressInfos = this.progressInfos.filter(function(obj, indexFile) {
        return indexFile !== index;
      });

      this.fileDatas = this.fileDatas.filter(function(obj, indexFile) {
        return indexFile !== index;
      });

      this.fileInfos = [];
      for (let i = 0; i < this.fileDatas.length; i++) {
        this.fileInfos.push({
          name: this.fileDatas[i].name,
          url: ""
        });
      }
      this.$emit("onChange", this.fileDatas);

      this.clear();
    },
    clear() {
      const input = this.$refs.fileupload;
      if (input) {
        input.type = "text";
        input.type = "file";
        this.selectedFiles = undefined;
      }
    },
    fileValidation(file) {
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
      const maxSize = 100 * 1024 * 1024;
      var allowedMimes = [
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/gif",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/msword",
        "application/zip",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

        "application/x-gzip",

        "application/postscript",
        "application/octet-stream",
        "application/x-indesign",
        ".fla",
        ".aep",
        ".prproj",
        ".sketch",
        ".xd",
        "video/quicktime",
        "audio/mp4",
        "video/x-m4v",
        "video/m4v",
        "audio/mpeg3",
        "audio/x-mpeg-3",
        "video/mpeg",
        "video/x-mpeg"
      ];

      const fileNameExit = this.progressInfos.filter(function(obj) {
        return obj.fileName == file.name;
      });

      if (file.size > maxSize) {
        return {
          success: false,
          message:
            "Whoops! The max file exceed 100MB. Please use external clound service and share the download url below."
          // message: "File size cannot be larger than 100MB!"
        };
      } else if (!allowedMimes.includes(file.type)) {
        return {
          success: false,
          message: "Invalid file type."
        };
      } else if (fileNameExit.length) {
        return {
          success: false,
          message: "Oops! You uploaded the same file."
        };
      } else {
        return {
          success: true,
          message: ""
          // message: "Uploaded the file successfully",
        };
      }
    }
  },
  mounted() {
    // UploadService.getFiles(this.quoteID).then(response => {
    //   this.fileInfos = response.data;
    // });
  }
};
</script>
