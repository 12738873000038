<template>
  <transition
    name="breakdown"
    mode="out-in"
    appear-active-class="animated fadeIn faster"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div :class="`breakdown ${isShow ? 'show' : ''}`">
      <div class="tr" v-for="(item, index) in breakdown" :key="index">
        <div class="td label" v-html="item.label"></div>
        <div class="td value" v-html="item.value"></div>
      </div>
      <!-- <div class="tr">
        <div class="td label">Job Number/Project Name</div>
        <div class="td value">{{ jobNumber }}</div>
      </div> -->
      <!-- <div class="tr">
        <div class="td label">Download URLs</div>
        <div class="td value"><span v-html="formattedDownloadURLs"></span></div>
      </div>
      <div class="tr">
        <div class="td label">Due on</div>
        <div class="td value">{{ formattedDueDate }}</div>
      </div> -->
      <div v-if="tempdata.product.modal.breakdownDescription">
        <div
          class="mt-5"
          v-for="(breakdownDescription, index) in tempdata.product.modal
            .breakdownDescription"
          :key="'bd' + index"
        >
          <h4 class="font-primary--bold">
            {{ breakdownDescription.title }}
          </h4>
          <p v-html="breakdownDescription.description"></p>
        </div>
      </div>
      <h4 class="mt-5 font-primary--bold">
        What’s not included
      </h4>
      <p v-html="tempdata.product.modal.notIncluded"></p>
      <div class="timing-uidelines" v-if="timingGuidelinesTable">
        <h4 class="mt-5 font-primary--bold">
          Timing Guidelines
        </h4>
        <p v-html="tempdata.product.modal.timingGuidelines"></p>

        <div class="tr">
          <div class="th label">JOB TYPE</div>
          <div class="th value">MINIMUM TIME*</div>
        </div>
        <div
          class="tr"
          v-for="(itemT, indexT) in tempdata.product.modal
            .timingGuidelinesTable"
          :key="'tgt' + indexT"
        >
          <div class="td label" v-html="itemT.jobType"></div>
          <div class="td value" v-html="itemT.minimumTime"></div>
        </div>
        <p>&nbsp;</p>
        <p v-html="tempdata.product.modal.timingGuidelinesBottom"></p>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "ResultBreakDown",
  props: {
    isShow: {
      type: Boolean,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["banner", "breakdown", "tempdata"]),
    formattedDueDate() {
      if (this.tempdata.due.date) {
        return DateTime.fromISO(this.tempdata.due.date).toFormat("dd LLL yyyy");
      }
      return "-";
    },
    jobNumber() {
      if (this.tempdata.detail.jobNumber) {
        return this.tempdata.detail.jobNumber;
      }
      return "-";
    },
    formattedDownloadURLs() {
      if (this.tempdata.detail.downloadURLs) {
        const downloadURLs = this.tempdata.detail.downloadURLs;
        return `<a class="downloadURL" target="_blank" href="${downloadURLs}">${downloadURLs}</p>`;
      }
      return "-";
    },
    timingGuidelinesTable() {
      if (
        this.tempdata.product.modal.timingGuidelinesTable &&
        this.tempdata.product.modal.timingGuidelinesTable.length
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {}
};
</script>
