<template>
  <div class="page--estimator">
    <header-component></header-component>
    <div id="main" class="main main-content">
      <div>
        <!-- MAIN ROUTER -->
        <transition
          name="router"
          mode="out-in"
          appear-active-class="animated fadeIn faster"
          enter-active-class="animated fadeIn faster"
          leave-active-class="animated fadeOut faster"
        >
          <router-view name="main"></router-view>
        </transition>
        <!-- END MAIN ROUTER -->
      </div>

      <modal-component></modal-component>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderComponent from "@/components/Header.vue";
import FooterComponent from "@/components/Footer.vue";
import ModalComponent from "@/components/Modal.vue";

export default {
  name: "Main",
  components: {
    HeaderComponent,
    FooterComponent,
    ModalComponent
  },
  computed: {
    ...mapGetters(["tempdata", "grid"])
  },
  methods: {
    ifLocalStorage(call) {
      if (localStorage.getItem("tempdata") && typeof call === "function") {
        call();
      } else {
        this.discardReset();
      }
    },
    discard() {
      window.onbeforeunload = function() {
        return "text";
      };
      window.onunload = function() {
        localStorage.removeItem("tempdata");
      };
    },
    discardReset() {
      window.onbeforeunload = function() {
        // clear return text
      };
    }
  },
  created() {
    this.$store.dispatch("setProductState");
    this.$store.dispatch("setBannerState");
    this.$store.dispatch("setEdmState");
    this.$store.dispatch("setVideoState");
    this.$store.dispatch("setAllowedEmailsState");
    this.ifLocalStorage(() => {
      let tempdata = localStorage.getItem("tempdata");
      this.$store.dispatch("setTempData", JSON.parse(tempdata));
      this.discard();
    });
  },
  updated() {
    this.ifLocalStorage(() => {
      this.discard();
    });
  }
};
</script>
