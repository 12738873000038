import { getEdm } from "@/services/edm";

export default {
  setEdmState: ({ commit }) => {
    getEdm().then(res => {
      commit("setEdmState", res);
    });
  },
  setEdmType: ({ commit }, payload) => {
    commit("setEdmType", payload);
  },
  setEdmStatus: ({ commit }, payload) => {
    commit("setEdmStatus", payload);
  },
  setEdmDesign: ({ commit }, payload) => {
    commit("setEdmDesign", payload);
  }
};
