<template>
  <div>
    <div :class="textAreaClassWrap">
      <textarea
        :class="textAreaClass"
        :type="type"
        :placeholder="placeholder"
        :value="localValue"
        :rows="rows"
        @input="onInputChange"
        @focus="isFocus = true"
        @blur="isFocus = false"
      />
    </div>
    <label v-if="isError" :class="labelErrorStyle">
      {{ errorMsg }}
    </label>
    <label v-else :class="labelStyle">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "TextArea",
  data() {
    return {
      localValue: "",
      isDirty: false,
      isError: this.error,
      errorMsg: this.errorMessage,
      isFocus: false
    };
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    skipDirtyCheck: {
      type: Boolean,
      default: false
    },
    rows: {
      type: String,
      default: ""
    },
    resizeable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showLabel() {
      return !this.isError;
    },
    textAreaClass() {
      return [
        "form-control",
        "textarea-text",
        { active: this.showLabel && !this.isError },
        { error: this.isError },
        this.resizeable ? "resizeable" : "not-resizeable"
      ];
    },
    labelStyle() {
      let classes = [
        "textarea-label",
        "font-primary--light"
        // "animated",
        // "faster"
      ];
      if (this.showLabel && !this.isError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    labelErrorStyle() {
      let classes = [
        "textarea-label",
        "font-primary--light",
        // "animated",
        // "faster",
        "error"
      ];
      if (this.isError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    textAreaClassWrap() {
      return [
        `textarea-wrapper`,
        {
          active:
            (this.showLabel && !this.isError) || (this.isFocus && !this.isError)
        },
        { error: this.isError }
      ];
    }
  },
  methods: {
    onInputChange(e) {
      this.localValue = e.target.value;
      this.$emit("onChange", e.target.value);
    }
  },
  mounted() {
    this.localValue = this.value;
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    error(newVal) {
      this.isError = newVal;
    },
    errorMessage(newVal) {
      this.errorMsg = newVal;
    },
    localValue(newVal) {
      this.$emit("onChange", newVal);
    }
  }
};
</script>
