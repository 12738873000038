<template>
  <div v-html="html"></div>
</template>

<script>
import edmtemplate from "@/library/email-template";
export default {
  name: "Email",
  data() {
    return {
      html: edmtemplate({ hideElement: true })
    };
  }
};
</script>
