<template>
  <div class="page page--product">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="video.type.title"></h1>
          <card-button
            :data="video.type.list"
            :default-selected="tempdata.video.type"
            type="video"
            @selectcard="selectVideoType"
          />
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage";
import objectFitPolyfill from "objectFitPolyfill";

export default {
  name: "VideoType",
  components: {
    CardButton,
    NavigationPage
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters(["video", "tempdata"]),
    prevTo() {
      return "Product";
    },
    nextTo() {
      if (!this.tempdata.video.type) {
        return "";
      }
      return this.tempdata.video.type &&
        this.tempdata.video.type.id === "VECTOR_VIDEO"
        ? "vector-video-type"
        : "video-config";
    }
  },
  methods: {
    selectVideoType(val) {
      if (val.published) {
        this.$store.dispatch("setVideoType", val);
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
        this.$router.push({ name: val.router_name });
      }
    }
  },
  mounted() {
    this.$store.dispatch("resetVideoConfig");
    this.$store.dispatch("setGrid", this.video.type.list.length);
    objectFitPolyfill;
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.product) {
        next();
      } else {
        next({ name: "Product" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
