<template>
  <div class="page page--banner-status">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="banner.status.title"></h1>
          <card-button
            :data="banner.status.list"
            @selectcard="selectBannerStatus"
            :default-selected="tempdata.banner.status"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationPage from "@/components/NavigationPage.vue";
import CardButton from "@/components/CardButton.vue";
import { NEW, EXIST, STC } from "@/library/constants";

export default {
  name: "BannerStatus",
  components: {
    CardButton,
    NavigationPage
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["banner", "tempdata"]),
    bannerStatus() {
      return this.tempdata.banner.status && this.tempdata.banner.status.id
        ? this.tempdata.banner.status.id
        : "";
    },
    bannerType() {
      return this.tempdata.banner.type && this.tempdata.banner.type.id
        ? this.tempdata.banner.type.id
        : "";
    },
    nextTo() {
      var rn;
      if (this.bannerStatus === NEW) {
        rn = "banner-master-quantity";
        if (this.bannerType === STC) {
          rn = "result";
        }
      } else if (this.bannerStatus === EXIST) {
        rn = "banner-variation";
        if (this.bannerType === STC) {
          rn = "banner-psd";
        }
      } else {
        rn = "";
      }
      return rn;
    },
    prevTo() {
      return "banner-quantity";
    }
  },
  methods: {
    selectBannerStatus(val) {
      this.$store.dispatch("setBannerStatus", val);
      this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      this.$router.push({ name: this.nextTo || val });
    }
  },
  mounted() {
    this.$store.dispatch("setMasterBannerQuantity", 0);
    this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
    this.$store.dispatch("setGrid", 2);
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.banner.quantity > 0) {
        next();
      } else {
        next({ name: "banner-quantity" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
