export default {
  setVideoState: (state, payload) => {
    state.video = payload;
  },
  setVideoType: (state, payload) => {
    state.tempdata.video.type = payload;
  },
  setVideoDuration: (state, payload) => {
    state.tempdata.video.duration = payload;
  },
  setIsVideoAssets: (state, payload) => {
    state.tempdata.video.isWithAssets = payload;
  },
  resetVideoConfig: state => {
    state.tempdata.video.duration = null;
    state.tempdata.video.isWithAssets = null;
  },
  disableAllVectorOptions: state => {
    state.video.vectorVariations.list.forEach(option => {
      option.isActive = false;
    });
  }
};
