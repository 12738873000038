import axios from "axios";

export async function createWrikeTask(data) {
  const TOKEN = process.env.VUE_APP_WRIKE_TOKEN;
  const FOLDERID = process.env.VUE_APP_WRIKE_FOLDERID;
  const API_URI = `https://www.wrike.com/api/v4/folders/${FOLDERID}/tasks`;
  const res = await axios({
    url: API_URI,
    method: "post",
    data,
    headers: {
      Authorization: `Bearer ${TOKEN}`
    }
  });
  return await res;
}
