import axios from "axios";

export async function sendEdm(data) {
  const EMAIL_HOST = process.env.VUE_APP_EMAIL_SERVICES_HOST;
  const API_URI = `${EMAIL_HOST}/services/email/send`;
  const res = await axios({
    url: API_URI,
    method: "post",
    data
  });
  return await res.data;
}
