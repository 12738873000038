import { getProduct } from "@/services/product";

export default {
  setProductState: ({ commit }) => {
    getProduct().then(res => {
      commit("setProductState", res);
    });
  },
  setType: ({ commit }, payload) => {
    commit("setType", payload);
  }
};
