import { getvideo } from "@/services/video";

export default {
  setVideoState: ({ commit }) => {
    getvideo().then(res => {
      commit("setVideoState", res);
    });
  },
  setVideoType: ({ commit }, payload) => {
    commit("setVideoType", payload);
  },
  setVideoDuration: ({ commit }, payload) => {
    commit("setVideoDuration", payload);
  },
  setIsVideoAssets: ({ commit }, payload) => {
    commit("setIsVideoAssets", payload);
  },
  disableAllVectorOptions: ({ commit }) => {
    commit("disableAllVectorOptions");
  },
  resetVideoConfig: ({ commit }) => {
    commit("resetVideoConfig");
  }
};
