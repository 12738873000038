import { SHB, RMB, AGB, NEW, STC } from "@/library/constants";

var totalHours = 0;
var totalCost = 0;

export default {
  banner: state => {
    return state.banner;
  },
  bannerTotalCost: state => {
    try {
      const n = Number;
      const bannerType = state.tempdata.banner.type.id;
      const bannerQuantityType = n(state.tempdata.banner.quantity);
      const bannerTypeHour = n(state.tempdata.banner.type.hour);
      const bannerTypeEstimate = n(state.tempdata.banner.type.estimate);
      const productTypePrice = n(state.tempdata.product.price.value);
      var cost = 0;

      if (bannerType === SHB || bannerType === RMB || bannerType === AGB) {
        let bannerFramePoint = n(state.tempdata.banner.frame.point);
        let bannerMasterQuantity = n(state.tempdata.banner.master_quantity);
        let bannerVariationQuantity = bannerQuantityType - bannerMasterQuantity;
        let bannerStoryboardHour = state.tempdata.banner.storyboard
          ? n(state.tempdata.banner.storyboard.hour)
          : 1;
        if (state.tempdata.banner.status.id === NEW) {
          let bannerStatusPointFirst = n(
            state.tempdata.banner.status.list[0].point
          );
          let bannerStatusPointSecond = n(
            state.tempdata.banner.status.list[1].point
          );
          let bannerMaster =
            bannerTypeHour *
            bannerStatusPointFirst *
            bannerFramePoint *
            bannerMasterQuantity;
          let bannerVariation =
            bannerTypeHour *
            bannerStatusPointSecond *
            bannerFramePoint *
            bannerVariationQuantity;
          let bannerStoryboard =
            bannerStoryboardHour > 1
              ? bannerQuantityType * bannerStoryboardHour
              : 0;

          totalHours = bannerMaster + bannerVariation + bannerStoryboard;
          // totalHours = round(totalHours, 0.05);

          cost = totalHours * bannerTypeEstimate * productTypePrice;
          // cost = round(cost, 0.5);

          // cost =
          //   (bannerMaster + bannerVariation + bannerStoryboard) *
          //   bannerTypeEstimate *
          //   productTypePrice;

          // totalHours = bannerMaster + bannerVariation + bannerStoryboard;
        } else {
          let bannerVariationPoint = n(state.tempdata.banner.variation.point);
          let bannerVariationHour = n(state.tempdata.banner.variation.hour);
          let bannerVariation =
            bannerTypeHour *
            bannerVariationPoint *
            bannerFramePoint *
            bannerQuantityType;
          let bannerStoryboard =
            bannerStoryboardHour > 1
              ? bannerQuantityType * bannerVariationHour
              : 0;

          totalHours = bannerVariation + bannerStoryboard;
          // totalHours = round(totalHours, 0.05);

          cost = totalHours * bannerTypeEstimate * productTypePrice;
          // cost = round(cost, 0.05);

          // cost =
          //   (bannerVariation + bannerStoryboard) *
          //   bannerTypeEstimate *
          //   productTypePrice;

          // totalHours = bannerVariation + bannerStoryboard;
        }
      } else if (bannerType === STC) {
        let bannerStatusPoint = 0;
        if (state.tempdata.banner.status) {
          if (state.tempdata.banner.status.id === NEW) {
            bannerStatusPoint = n(state.tempdata.banner.status.point);
          } else {
            bannerStatusPoint = n(state.tempdata.banner.psd.point);
          }
        }

        totalHours = bannerTypeHour * bannerStatusPoint * bannerQuantityType;
        // totalHours = round(totalHours, 0.05);

        cost = totalHours * bannerTypeEstimate * productTypePrice;
        // cost = round(cost, 0.05);

        // cost =
        //   bannerTypeHour *
        //   bannerStatusPoint *
        //   bannerQuantityType *
        //   bannerTypeEstimate *
        //   productTypePrice;

        // totalHours = bannerTypeHour * bannerStatusPoint * bannerQuantityType;
      }
      totalCost = cost;
      return cost;
    } catch {
      return 0;
    }
  },
  bannerBreakdown: state => {
    try {
      const n = Number;
      const bannerType = state.tempdata.banner.type.id;
      const bannerTypeEstimate = n(state.tempdata.banner.type.estimate);
      const productTypePrice = n(state.tempdata.product.price.value);
      var breakdown = [
        {
          label: state.products.label,
          value: state.tempdata.product.title
        },
        {
          label: state.banner.type.label,
          value: state.tempdata.banner.type.title
        },
        {
          label: state.banner.quantity.label,
          value: state.tempdata.banner.quantity
        }
      ];
      if (bannerType === SHB || bannerType === RMB || bannerType === AGB) {
        breakdown.push(
          ...[
            {
              label: state.banner.frames.label,
              value: state.tempdata.banner.frame.label
            },
            {
              label: state.banner.status.label,
              value: state.tempdata.banner.status.label
            }
          ]
        );
        if (state.tempdata.banner.status.id === NEW) {
          breakdown.push({
            label: "How many master?",
            value: state.tempdata.banner.master_quantity
          });
        } else {
          breakdown.push({
            label:
              "How big are the variations from the existing banner key visuals?",
            value: state.tempdata.banner.variation.label
          });
        }
        if (bannerType === SHB || bannerType === RMB) {
          breakdown.push({
            label: state.banner.storyboard.label,
            value: state.tempdata.banner.storyboard.title
          });
        }
      }

      // var totalHoursDeveloper = totalHours * state.banner.developer.value;
      // var totalHoursQA = totalHours * state.banner.QA.value;
      // var totalHoursTO = totalHours * state.banner.TO.value;
      // var totalHoursDeveloper = Math.round(
      //   totalHours * state.banner.developer.value
      // )
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // var totalHoursQA = Math.round(totalHours * state.banner.QA.value)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // var totalHoursTO = Math.round(totalHours * state.banner.TO.value)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      totalCost = round(totalCost, 1);
      totalHours = totalCost / bannerTypeEstimate / productTypePrice;

      // var totalHoursDeveloper = totalHours * state.banner.developer.value;
      // var totalHoursQA = totalHours * state.banner.QA.value;
      // var totalHoursTO = totalHours * state.banner.TO.value;

      var totalHoursDeveloper =
        ((totalHours * state.banner.developer.value).toFixed(2) * 100) / 100;

      var totalHoursQA =
        ((totalHours * state.banner.QA.value).toFixed(2) * 100) / 100;

      var totalHoursTO =
        ((totalHours * state.banner.TO.value).toFixed(2) * 100) / 100;

      // totalHoursDeveloper = totalHoursDeveloper > 0 ? totalHoursDeveloper : 0.5;
      // totalHoursQA = totalHoursQA > 0 ? totalHoursQA : 0.5;
      // totalHoursTO = totalHoursTO > 0 ? totalHoursTO : 0.5;

      totalHoursDeveloper = round(totalHoursDeveloper, 0.5);
      totalHoursQA = round(totalHoursQA, 0.5);
      totalHoursTO = round(totalHoursTO, 0.5);

      breakdown.push({
        label: state.banner.developer.label,
        value: totalHoursDeveloper + " Hours"
      });

      breakdown.push({
        label: state.banner.QA.label,
        value: totalHoursQA + " Hours"
      });

      breakdown.push({
        label: state.banner.TO.label,
        value: totalHoursTO + " Hours"
      });

      return breakdown;
    } catch {
      return [];
    }
  }
};

function round(value, step) {
  step || (step = 1.0);
  var inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}
