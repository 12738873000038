import product from "./getters/product";
import banner from "./getters/banner";
import edm from "./getters/edm";
import video from "./getters/video";
import allowedEmails from "./getters/allowedEmails";
import { WEB_BANNER, EDM, VIDEO } from "@/library/constants";

export default {
  ...product,
  ...banner,
  ...edm,
  ...video,
  ...allowedEmails,
  tempdata: state => {
    return state.tempdata;
  },
  detail: state => {
    return state.tempdata.detail;
  },
  user: state => {
    return state.tempdata.user;
  },
  start: state => {
    return state.tempdata.start;
  },
  due: state => {
    return state.tempdata.due;
  },
  grid: state => {
    return state.grid;
  },
  modal: state => {
    return state.modal;
  },
  cost: (state, getters) => {
    const TYPE = state.tempdata.product ? state.tempdata.product.id : null;
    switch (TYPE) {
      case WEB_BANNER:
        return getters.bannerTotalCost;
      case EDM:
        return getters.edmTotalCost;
      case VIDEO:
        return getters.videoTotalCost;
      default:
        return 0;
    }
  },
  breakdown: (state, getters) => {
    const TYPE = state.tempdata.product ? state.tempdata.product.id : null;
    switch (TYPE) {
      case WEB_BANNER:
        return getters.bannerBreakdown;
      case EDM:
        return getters.edmBreakdown;
      case VIDEO:
        return getters.videoBreakdown;
      default:
        return [];
    }
  },
  quoteID: state => {
    return state.quoteID;
  }
};
