<template>
  <div>
    <div :class="containerStyle" v-on-clickaway="dropUp">
      <button type="button" :class="inputStyle" @click="toggleDropdown()">
        {{ showText }}
      </button>
      <ul :class="optionStyle">
        <li
          v-for="(option, index) in options"
          :key="index"
          @click="setValue(option)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
    <label v-if="!showError" :class="labelStyle">
      {{ label }}
    </label>
    <label v-if="showError" :class="labelErrorStyle">
      {{ errorMessage }}
    </label>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  name: "SelectDropdown",
  data() {
    return {
      localValue: null,
      dropdownIsOpen: false,
      isDirty: false
    };
  },
  props: {
    options: Array,
    value: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    skipDirtyCheck: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showLabel: function() {
      return Boolean(this.localValue);
    },
    containerStyle() {
      return ["dropdown-container", this.dropdownIsOpen ? "opened" : ""];
    },
    inputStyle: function() {
      return [
        "form-control",
        "select-dropdown",
        { active: this.showLabel && !this.showError },
        { error: this.showError }
      ];
    },
    labelStyle: function() {
      let classes = [
        "input-label",
        "font-primary--light",
        "animated",
        "faster"
      ];
      if (this.showLabel && !this.showError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    labelErrorStyle: function() {
      let classes = [
        "input-label",
        "font-primary--light",
        "animated",
        "faster",
        "error"
      ];
      if (this.showError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    optionStyle: function() {
      let classes = ["dropdown-options", "shadow", "animated"];
      if (this.dropdownIsOpen) {
        classes = [...classes, "zoomIn", "flash"];
      } else {
        classes = [...classes, "hide"];
      }
      return classes;
    },
    showText: function() {
      return this.localValue && this.localValue.label
        ? this.localValue.label
        : this.placeholder;
    },
    showError() {
      return (
        (this.skipDirtyCheck && this.error) || (this.error && this.isDirty)
      );
    }
  },
  methods: {
    setValue(value) {
      this.localValue = value;
      this.$emit("onChange", value);
      this.toggleDropdown();
    },
    toggleDropdown() {
      this.dropdownIsOpen = !this.dropdownIsOpen;
    },
    dropUp() {
      this.dropdownIsOpen = false;
    }
  },
  mounted() {
    this.localValue = this.value;
  },
  watch: {
    value: function(newVal) {
      this.localValue = newVal;
    }
  }
};
</script>
