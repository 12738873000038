import axios from "axios";

export async function getBanner() {
  const res = await axios.get("./mock/api.banner.json");
  return await res.data;
}

export async function getStaticBanner(url) {
  const res = await axios.get(url);
  return await res.data;
}
