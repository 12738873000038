export default (args) => {
  const { hideElement } = args;
  const HOST = process.env.VUE_APP_HOST;
  const URL = `${HOST}/img/edm`;
  return `
<!doctype html xmlns="http://www.w3.org/1999/xhtml"
xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office">
  <html>
    <head>
        <!--[if gte mso 9]>
        <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->

        
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <title>Production Request</title>

        <style type="text/css">
          #outlook a { padding:0; } 
          body { margin:0; padding:0; width:100% !important; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%; }
          .ExternalClass { width:100%; } 
          .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div { line-height: 100%; } 
          .backgroundTable { line-height: 100% !important; margin:0; padding:0; width:100% !important; }
          img { border:none; outline:none; text-decoration:none; -ms-interpolation-mode: bicubic; }
          a img { border:none; }
          .image_fix { display:block; }
          p { margin: 0px 0px !important; }
          a { text-decoration:none!important; }

          table td { border-collapse: collapse; }
          table { border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt; }
          table.full { clear: both; width: 100%; }
          table.largeTwoColumn { max-width: 100% !important; }
          table.floating, td.floating { border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt; }  
          td.empty { font-size:1px; line-height:1px; mso-line-height-rule: exactly; }
      

          
          @media only screen and (max-width: 640px) {
            a[href^="tel"], a[href^="sms"] { cursor: default; pointer-events: none; text-decoration: none; }
            .mobile_link a[href^="tel"], .mobile_link a[href^="sms"] { cursor: default; pointer-events: auto; text-decoration: default; }
            table[class*=devicewidth], .devicewidth { width: 440px!important; }
            table[class*=paddedTable], td[class*=paddedTable], .paddedTable { width: 388px!important; }
            table[class*=devicewidthinner], .devicewidthinner { width: 400px!important; }
            table[class*=removeMobile], td[class*=removeMobile], br[class*=removeMobile], .removeMobile{ display:none!important; }

            table[class*=content_inside], .content_inside{ width:388px !important; }
            table[class*=devicewidthOutside], .devicewidthOutside { width: 440px!important; }
            table[class*=colProd], .colProd{ width:100% !important;}

            *[class=hide],.hide {display:none!important; width:0px !important;}
            *[class=show], .show {width:100%!important; height:auto!important;display:table!important;}
            *[class=me], .me {display:block!important; width:100%!important; max-height:inherit!important; overflow:visible!important;}
            *[class=themoney], .themoney {display: block!important; width:100% !important;height:auto !important; float:none!important;}
            td[class*=spacer], .spacer{width:6% !important}
            *[class*=separatorTable], .separatorTable { display: block; width: 100% !important;height: 30px !important;}
            *[class*=separatorTable] td, .separatorTable td, .separatorTableTd{ height: 30px !important;width: 100% !important; }
            *[class*=ctaTable], .ctaTable {width: 100% !important;}
            *[class*=ctaRow], .ctaRow {display: none !important; width: 0px !important;}
            *[class*=ctaTable] td, .ctaTable td {height: 48px !important;font-size: 16px !important;}
            *[class*=ctaTable] td a, .ctaTable td a{padding-top: 0px !important;padding-bottom: 0px !important;}
            .padding-bottom{
              padding-bottom: 25px !important;
            }
            .fontSup{vertical-align: 8px !important;}
          }

          
          @media only screen and (max-width: 480px) {
            a[href^="tel"], a[href^="sms"] { cursor: default; pointer-events: none; text-decoration: none; }
            .mobile_link a[href^="tel"], .mobile_link a[href^="sms"] { cursor: default; pointer-events: auto; text-decoration: default; }
            table[class*=devicewidth], .devicewidth { width: 280px!important; }
            table[class*=paddedTable], td[class*=paddedTable], .paddedTable { width: 250px!important; }
            table[class*=devicewidthinner], .devicewidthinner { width: 260px!important; }

            table[class*=content_inside], .content_inside{ width:100% !important; }
            table[class*=devicewidthOutside], .devicewidthOutside { width: 100%!important; }
            table[class*=devicewidth], .devicewidth { width: 100%!important; }
            table[class*=paddedTable], td[class*=paddedTable], .paddedTable { width: 100%!important; }
            td[class*=spacer], .spacer{width:6% !important}

            *[class=showMobile], .showMobile {display:block!important;}

            *[class*=heading], .heading{font-size: 25px !important; line-height: 27px !important}
            *[class*=subheading], .subheading{font-size: 24px !important; line-height: 24px !important}

            .fontSup{vertical-align: 6px !important;}

            

          }

          @media only screen and (max-width: 370px) {
            *[class=showMobileText], .showMobileText {display:block!important;}

          }

          .backgroundTable, body { background-color: #383838; }
          .backgroundTableGrey { background-color: #383838; }
          .containerPanel { background-color: #ffffff; }
          .purplePanel { background-color: #EFE7F7;}
          .pinkPanel { background-color: #EECAE0;}
          .contentPanel { background-color: #ffffff; }
          .separatorPanel { background-color: #ffffff; }
          .separatorLine { background-color: #ffffff; }
          .containerPanelOutside{background:#ffffff;}
       
          sup {vertical-align: super;line-height: 0;}

          .mac_fix a{color:#ffffff !important}
          .mac_fix2 a{color:#2a2a2a !important}

          a.linkblack {text-decoration:none !important; color: #000000 !important; display: block !important; width: 100% !important;}
          a.linkwhite {text-decoration:none !important; color: #ffffff !important;}
          a.linkwhite-underline {text-decoration:underline !important; color: #ffffff !important;}
          a.linkgray {text-decoration:none !important; color: #878787 !important;}
          a.linkgray-underline {text-decoration:underline !important; color: #878787 !important;}
          a.linkgrey-underline {text-decoration:underline !important; color: #dadada !important;}
          a.linkorange-underline {text-decoration:underline !important; color: #ff6b00 !important;}
          a.linkgreen-underline {text-decoration:underline !important; color: #3daf2c !important;}
          a.linklightblue-underline {text-decoration:underline !important; color: #54c0e8 !important;}
          a.linkmagenta-underline {text-decoration:underline !important; color: #bf549f !important;}
          a.linkblack-underline {text-decoration:underline !important; color: #2a2a2a !important;}
          a.linkpink, .linkpink a, .linkpink {color: #e5007b !important;}
          a.link-black-underline, .link-black-underline a{text-decoration:underline !important;color:#2a2a2a !important;}
          .link2-black-nounderline a{text-decoration:none !important;color:#000000 !important;}
          .link-black-nounderline a{text-decoration:none !important;color:#2a2a2a !important;}

          
          .y_fix{margin: 0 auto;}


        </style>
    </head>

    <body style="background-color:#383838;margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;padding-top:0;padding-bottom:0;padding-right:0;padding-left:0;width:100% !important;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;" >

       
        <!-- Start Header -->
        <table width="100%" cellpadding="0" cellspacing="0" border="0" class="backgroundTableGrey" style="${
          hideElement ? 'display:none;' : ''
        }border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;background-color:#383838;" ><tbody><tr><td width="100%" style="border-collapse:collapse;" >

            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidthOutside y_fix" style="width:600px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" ><tbody><tr>
            <td width="600" align="center" style="border-collapse:collapse;" >

            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth y_fix" style="width:600px;background-color:#F2F2F2;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" ><tbody><tr>

                <td width="50" class="spacer" valign="top" align="left" style="border-collapse:collapse;" ><img src="${URL}/spacer.png" alt="space" style="border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" ></td>

                <td width="530" align="center" style="border-collapse:collapse;" >

                    <table width="530" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth paddedTable" style="width:530px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                        <tbody>



                            <!-- Spacing -->
                            <tr>
                                <td width="530" height="15" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" >
                                  <img src="${URL}/spacer.png" height="15" alt="space" style="border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" >
                                </td>
                            </tr>
                            <!-- Spacing -->

                            <!-- content -->
                            <tr>
                                <td width="530" align="center" style="line-height:10px;border-collapse:collapse;" >
                                    <span style="font-family:Helvetica, Arial, sans-serif;color:#878787;font-size:11px;line-height:16px;" >Email not displaying correctly? <webversion class="linkgray-underline" style="text-decoration:underline;color:#878787;" ><a href="${HOST}/production-request"><u>View in your browser</u></a></webversion>
                                    </span>
                                </td>
                            </tr>
                            <!-- End of content -->

                            <!-- Spacing -->
                            <tr>
                                <td width="530" height="14" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                            </tr>
                            <!-- Spacing -->

                            <!-- iPad fix -->
                            <tr>
                                <td width="530" align="left" style="border-collapse:collapse;" >
                                    <table width="530" align="left" border="0" cellpadding="0" cellspacing="0" class="content_inside" style="width:530px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                                        <tbody><tr>
                                                <td width="265" height="1" align="left" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                                <td width="265" height="1" align="left" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                        </tr></tbody>
                                    </table>
                                </td>
                            </tr>
                            <!-- End of iPad fix -->

                        </tbody>
                    </table>

                </td>

                <td width="50" class="spacer" valign="top" align="left" style="border-collapse:collapse;" ><img src="${URL}/spacer.png" alt="space" style="border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" ></td>

            </tr></tbody></table>

            </td>
            </tr></tbody></table>

        </td></tr></tbody></table>
        <!-- End of Header -->
       
         <!-- Red Area -->
        <table width="100%" cellpadding="0" cellspacing="0" border="0" class="backgroundTableGrey" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;background-color:#383838;" ><tbody><tr><td width="100%" style="border-collapse:collapse;" >


            <!-- Start Content wrapper -->
            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidthOutside containerPanelOutside y_fix" style="width:600px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;background-color:#ffffff;background-image:none;background-repeat:repeat;background-position:top left;background-attachment:scroll;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;min-height: 80vh;" ><tbody><tr>
              <td width="600" align="center" valign="top" style="width:600px;border-collapse:collapse;background-color:#ffffff;vertical-align:top;" >

                <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth y_fix" style="width:600px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" >
                    <tbody>

                        <tr class="hide">
                            <td width="600"  align="center" style="border-collapse:collapse;mso-line-height-rule:exactly;" >
                                <a href="${HOST}" style="text-decoration: none !important;">
                                    <img src="${URL}/main_header_desktop.jpg" alt="main header image" border="0" style="display:block;width:100%;border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" >
                                </a>
                            </td>
                        </tr>

                        <!--[if !mso]><!-->
                        <tr  class="me" style="width:0;overflow:hidden;float:left;display:none;max-height:0px;mso-hide:all;" >
                            <td width="600" align="center"  class="themoney empty" style="border-collapse:collapse;font-size:1px;mso-line-height-rule:exactly;line-height:1px;mso-mso-line-height-rule:exactly;line-height-rule:exactly;" >
                                <a href="${HOST}" style="text-decoration: none !important;">    
                                    <img src="${URL}/main_header_mobile.jpg" alt="main header image" border="0" style="display:block;width:100%;border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" >
                                </a>
                            </td>
                        </tr>
                        <!--<![endif]-->

                    </tbody>
                </table>

                <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth containerPanel y_fix" style="width:600px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;background-color:#ffffff;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" >
                    <tbody><tr>

                    <td width="50" class="spacer" valign="top" align="left" style="width:50px;border-collapse:collapse;" ><img src="${URL}/spacer.png" alt="space" style="border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" ></td>

                    <td width="500" align="center" style="width:500px;border-collapse:collapse;" >

                        <table width="500" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth paddedTable" style="width:500px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                            <tbody>

                                <!-- Spacing -->
                                <tr>
                                    <td width="100%" height="40" class="empty" style="width:100%;border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                </tr>
                                <!-- Spacing -->

                                <!-- content -->
                                <tr>
                                    <td width="100%" align="left" class="contentPanel" style="width:100%;border-collapse:collapse;background-color:#ffffff;" >

                                        <table width="500" align="center" border="0" cellpadding="0" cellspacing="0" class="content_inside mac_fix" style="width:500px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                                            <tbody>
                                        
                                              <!-- Spacing -->
                                              <tr>
                                                  <td width="100%" height="20" class="empty" style="width:100%;border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                              </tr>
                                              <!-- Spacing -->
                                              
                                              <tr>
                                                  <td width="500" align="center" style="line-height:25px;width:500px;border-collapse:collapse;text-align:center;" >
                                                  
                                                  <!-- Copy -->
                                                  
                                                  <table width="450" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth paddedTable" style="width:450px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                                                    <tbody>

                                                      <!-- content -->
                                                      <tr>
                                                        <td width="450" align="center" style="line-height:32px;border-collapse:collapse;" >
                                                          <span style="font-family:Arial,Helvetica, sans-serif;color:#000000;font-size:24px;line-height:32px;" >
                                                            Your request has been submitted, we'll get back to you soon.
                                                          </span>        
                                                        </td>
                                                      </tr>
                                                      <!-- Spacing -->
                                                      <tr>
                                                          <td width="450" height="8" class="empty" style="width:450px;border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                                      </tr>
                                                
                                                      <tr>
                                                          <td width="450" height="8" class="empty" style="width:450px;border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                                      </tr>
                                                   
                                                      <tr>
                                                          <td width="450" height="8" class="empty" style="width:450px;border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                                      </tr>
                                                      <!-- Spacing -->

                                                      <!-- End of content -->

                                                    

                                                    </tbody>
                                                  </table>

                                                  <!--// Copy -->

                                                
                                                </td>
                                              </tr>
                                              <!-- Spacing -->
                                              <tr>
                                                  <td width="450" height="23" class="empty" style="width:450px;border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                              </tr>
                                              <!-- Spacing -->
                                              
                                              
                                            


                                              <!-- content -->
                                              
                                              <!-- Spacing -->
                                              <tr>
                                                  <td width="450" height="40" class="empty" style="width:450px;border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                              </tr>
                                              <!-- Spacing -->
                                              

                                              
                                            </tbody>
                                        </table>

                                    </td>
                                </tr>
                                <!-- end of content -->
                                
                               
                                

                                <!-- iPad fix -->
                                <tr>
                                    <td width="100%" align="left" style="width:100%;border-collapse:collapse;" >
                                        <table width="100%" align="left" border="0" cellpadding="0" cellspacing="0" class="content_inside" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                                            <tbody><tr>
                                                    <td width="50%" height="1" align="left" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                                    <td width="50%" height="1" align="left" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                            </tr></tbody>
                                        </table>
                                    </td>
                                </tr>
                                <!-- End of iPad fix -->


                            </tbody>
                        </table>

                    </td>

                    <td width="50" class="spacer" valign="top" align="left" style="width:50px;border-collapse:collapse;" ><img src="${URL}/spacer.png" alt="space" style="border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" ></td>

                </tr></tbody></table>
                
                
                

            </td>
            </tr></tbody></table>
            <!-- End of Content wrapper -->

        </td></tr></tbody></table>
        <!-- End of Red area -->


        <!-- Start Footer -->
        <table width="100%" cellpadding="0" cellspacing="0" border="0" class="backgroundTableGrey" style="border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;background-color:#383838;" ><tbody><tr><td width="100%" style="border-collapse:collapse;" >

            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidthOutside y_fix" style="width:600px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;" ><tbody><tr>
            <td width="600" align="center" style="border-collapse:collapse;" >

            <table width="600" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth y_fix" style="width:600px;background-color:#f4f4f4;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;margin-top:0;margin-bottom:0;margin-right:auto;margin-left:auto;min-height: 20vh;background: #f4f4f4;" ><tbody><tr>

                <td width="35" class="spacer" valign="top" align="left" style="border-collapse:collapse;" ><img src="${URL}/spacer.png" alt="space" style="border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" ></td>

                <td width="530" align="center" style="border-collapse:collapse;" >

                    <table width="530" cellpadding="0" cellspacing="0" border="0" align="center" class="devicewidth paddedTable" style="width:530px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                        <tbody>
                           
                           
                            <!-- Spacing -->
                            <tr>
                                <td width="530" height="30" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                            </tr>
                            <!-- Spacing -->


                      

                            

                            <!-- content -->
                            <tr>
                                <td width="530" align="center" style="line-height:10px;border-collapse:collapse;" >
                                    <span style="font-family:Arial, Helvetica, sans-serif;color:#2a2a2a;font-size:11px;line-height:16px;" >
                                      *The estimates are not final, our production team will review the requirements and get back to you with more details. I you have any question please contact wayan.agoes@ogilvy.com or +62 81805320991.
                                    </span>
                                </td>
                            </tr>
                            <!-- End of content -->      
                            
                            
                           <!--  <tr>
                             <td width="530" align="center" style="line-height:10px;border-collapse:collapse;" >
                               <span style="font-family:Helvetica, Arial, sans-serif;color:#2a2a2a;font-size:11px;line-height:16px;" >    
                                 Receiving too many emails? <unsubscribe class="linkblack-underline" style="text-decoration:underline;color:#2a2a2a;" >
                                   <u>Click&nbsp;here&nbsp;to&nbsp;unsubscribe</u></unsubscribe>
                               </span>
                                 
                             </td>
                                      
                           </tr> -->
                           
                           
                             <!-- Spacing -->
                            <tr>
                                <td width="530" height="30" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                            </tr>
                            <!-- Spacing -->
                           
                           
                         
                            

                            <!-- iPad fix -->
                            <tr>
                                <td width="530" align="left" style="border-collapse:collapse;" >
                                    <table width="530" align="left" border="0" cellpadding="0" cellspacing="0" class="content_inside" style="width:530px;border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt;" >
                                        <tbody><tr>
                                                <td width="265" height="1" align="left" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                                <td width="265" height="1" align="left" class="empty" style="border-collapse:collapse;font-size:1px;line-height:1px;mso-line-height-rule:exactly;" ><!--[CDATA[&nbsp;]]--></td>
                                        </tr></tbody>
                                    </table>
                                </td>
                            </tr>
                            <!-- End of iPad fix -->

                        </tbody>
                    </table>

                </td>

                <td width="35" class="spacer" valign="top" align="left" style="border-collapse:collapse;" ><img src="${URL}/spacer.png" alt="space" style="border-style:none;outline-style:none;text-decoration:none;-ms-interpolation-mode:bicubic;" ></td>

            </tr></tbody></table>

            </td>
            </tr></tbody></table>

        </td></tr></tbody></table>
        <!-- End of Footer -->

    

</body></html>
  `;
};
