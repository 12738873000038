import { cloneDeep } from "lodash";
import tempdata from "./state/tempdata";
export default {
  products: null,
  banner: null,
  edm: null,
  video: null,
  grid: 1,
  tempdata: cloneDeep(tempdata),
  modal: {
    status: false,
    data: {}
  },
  allowedEmails: null
};
