import { getBanner, getStaticBanner } from "@/services/banner";

export default {
  setBannerState: ({ commit }) => {
    getBanner().then(res => {
      commit("setBannerState", res);
    });
  },
  setBannerType: ({ commit }, payload) => {
    commit("setBannerType", payload);
    getStaticBanner(payload.src).then(res => {
      commit("addBannerState", res);
    });
  },
  setBannerQuantity: ({ commit }, payload) => {
    commit("setBannerQuantity", payload);
  },
  setBannerFrame: ({ commit }, payload) => {
    commit("setBannerFrame", payload);
  },
  setBannerStatus: ({ commit }, payload) => {
    commit("setBannerStatus", payload);
  },
  setMasterBannerQuantity: ({ commit }, payload) => {
    commit("setMasterBannerQuantity", payload);
  },
  setBannerVariation: ({ commit }, payload) => {
    commit("setBannerVariation", payload);
  },
  setBannerStoryBoard: ({ commit }, payload) => {
    commit("setBannerStoryBoard", payload);
  },
  setBannerPsd: ({ commit }, payload) => {
    commit("setBannerPsd", payload);
  }
};
