<template>
  <nav class="nav">
    <a
      href="javascript:;"
      @click.prevent="navPrevTo"
      role="prev"
      :class="classBtnPrev"
    >
      <span class="icon"></span>
    </a>
    <a
      href="javascript:;"
      @click.prevent="navNextTo"
      role="next"
      :class="classBtnNext"
    >
      <span class="icon"></span>
    </a>
  </nav>
</template>

<script>
export default {
  name: "NavigationPage",
  props: {
    prevTo: {
      type: String,
      default: () => ""
    },
    nextTo: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {};
  },
  computed: {
    classBtnPrev() {
      return ["nav__btn nav__btn-left", this.prevTo ? null : "disabled"];
    },
    classBtnNext() {
      return ["nav__btn nav__btn-right", this.nextTo ? null : "disabled"];
    }
  },
  methods: {
    navNextTo() {
      this.$emit("on-next-to");
      if (this.nextTo) {
        this.$router.push({ name: this.nextTo });
      }
    },
    navPrevTo() {
      this.$emit("on-prev-to");
      if (this.prevTo) {
        this.$router.push({ name: this.prevTo });
      }
    }
  }
};
</script>
