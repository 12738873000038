<template>
  <div class="page page--home">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="title font-primary--bold">
            {{ "Production estimate tool" }}
          </h1>
          <h2 class="subtitle font-primary--light">
            {{ "Please submit your email to start using the tool" }}
          </h2>
          <form class="form" @submit.prevent="onSubmit">
            <div class="form-group">
              <TextInput
                type="email"
                name="email"
                :value="tempdata.user.email"
                @onChange="onInputChange"
                placeholder="ENTER YOUR EMAIL HERE"
                :error="!emailValidation.isValid"
                :errorMessage="emailValidation.message"
                :skipDirtyCheck="skipDirtyCheck"
              ></TextInput>
              <button
                class="btn btn-primary"
                :class="`${!emailValidation.isValid ? 'disabled' : ''}`"
              >
                <span class="caret">
                  {{ "submit" }}
                  <span class="icon"></span>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput";

export default {
  name: "Home",
  components: {
    TextInput
  },
  data() {
    return {
      required: true,
      skipDirtyCheck: false
    };
  },
  mounted() {
    this.$store.dispatch("setGrid", 1);
  },
  computed: {
    ...mapGetters(["tempdata"]),
    emailValidation() {
      if (!this.email) {
        return {
          isValid: false,
          message: "Email is required."
        };
      }

      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let isError = !re.test(String(this.email).toLowerCase());
      if (isError) {
        return {
          isValid: false,
          message: "Invalid email address."
        };
      }

      // List of the domain name that are accepted in the email field
      let allowed = this.$store.state.allowedEmails.domain;
      if (this.email && this.email.includes("@")) {
        let str = this.email.split("@").slice(1);
        if (allowed.indexOf(str[0]) == -1) {
          return {
            isValid: false,
            message: "Your email domain name is not registered with us."
          };
        }
      }

      return {
        isValid: true,
        message: ""
      };
    },
    email: {
      set(email) {
        this.$store.dispatch("setUser", { email });
      },
      get() {
        return this.tempdata.user.email;
      }
    }
  },
  methods: {
    onInputChange(val) {
      this.email = val;
    },
    onSubmit() {
      this.skipDirtyCheck = true;
      if (this.emailValidation.isValid) {
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
        this.$router.push({ name: "Product" });
      }
      this.$router.push({ name: "Product" });
    }
  }
};
</script>
