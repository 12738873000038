<template>
  <div class="datepicker">
    <Datepicker
      v-model="datePickerModel"
      @input="onDatePickerChange"
      :input-id="idDateTime"
      :disabled-dates="disabledDates"
      @closed="onDatePickerClose"
    />
    <div :class="datePickerClass">
      <img alt="Calendar" class="datepicker__icon" :src="getCalendarIcon()" />
      {{ datePickerText() }}
    </div>
    <label v-if="isError && !firstRender" :class="labelErrorStyle">
      {{ errorMsg }}
    </label>
    <label v-else :class="labelStyle">
      {{ label }}
    </label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "vue-datetime/dist/vue-datetime.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";

export default {
  name: "DatePicker",
  components: {
    Datepicker
  },
  data() {
    return {
      isFilled: false,
      isError: this.error,
      errorMsg: this.errorMessage,
      isFirstRender: this.firstRender
    };
  },
  props: {
    idDateTime: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    firstRender: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(["tempdata"]),
    datePickerModel: {
      set(date) {
        switch (this.idDateTime) {
          case "start-date":
            this.$store.dispatch("setStart", { date });
            break;
          case "due-date":
            this.$store.dispatch("setDue", { date });
            break;
        }
        this.$emit("onChange", date);
      },
      get() {
        switch (this.idDateTime) {
          case "start-date":
            return this.tempdata.start.date;
          case "due-date":
            return this.tempdata.due.date;
          default:
            return null;
        }
      }
    },
    datePickerClass() {
      return ["datepicker__picker", this.idDateTime];
    },
    labelStyle() {
      let classes = [
        "datepicker__label",
        "font-primary--light",
        "animated",
        "faster"
      ];
      if (!this.isError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    labelErrorStyle() {
      let classes = [
        "datepicker__label",
        "font-primary--light",
        "animated",
        "faster",
        "error"
      ];
      if (this.isError) {
        classes = [...classes, "fadeInLeft"];
      } else {
        classes = [...classes, "fadeOutLeft"];
      }
      return classes;
    },
    minDatetime() {
      let myDate = new Date();
      myDate.setDate(myDate.getDate() + 1);
      return myDate.toISOString();
    },
    disabledDates() {
      let minDate = new Date();
      minDate.setDate(minDate.getDate());

      return {
        to: minDate,
        days: [6, 0]
      };
    }
  },
  methods: {
    onDatePickerClick() {
      const el = document.querySelector("#" + this.idDateTime);
      el.click();
    },
    onDatePickerClose() {
      switch (this.idDateTime) {
        case "start-date":
          this.$emit("onClose", this.tempdata.start.date);
          break;
        case "due-date":
          this.$emit("onClose", this.tempdata.due.date);
          break;
      }
    },
    onDatePickerChange(value) {
      switch (this.idDateTime) {
        case "start-date":
          this.startDate = value;
          break;
        case "due-date":
          this.dueDate = value;
          break;
        default:
          break;
      }
    },
    datePickerText() {
      let date;
      let msg;
      switch (this.idDateTime) {
        case "start-date":
          msg = "Enter start date";
          date = this.tempdata.start.date;
          break;
        case "due-date":
          msg = "Enter due date";
          date = this.tempdata.due.date;
          break;
        default:
          break;
      }
      if (date) {
        this.isFilled = true;
        var el = document.querySelector("." + this.idDateTime);
        if (el) {
          el.classList.add("filled");
        }
        switch (this.idDateTime) {
          case "start-date":
            return `Start on ${moment(date).format("DD MMM YYYY")}`;
          case "due-date":
            return `Due on ${moment(date).format("DD MMM YYYY")}`;
        }
      }
      return msg;
    },
    getCalendarIcon() {
      return this.isFilled
        ? require("../assets/images/icon/calendar-icon-white.png")
        : require("../assets/images/icon/calendar-icon.png");
    },
    customFormatter(date) {
      return moment(date).format("yyyy-MM-dd");
    }
  },
  watch: {
    error(newVal) {
      this.isError = newVal;
    },
    errorMessage(newVal) {
      this.errorMsg = newVal;
    }
  }
};
</script>
