<template>
  <div class="page">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1
            class="font-primary--light"
            v-html="video.vectorVariations.title"
          ></h1>
          <card-button
            :data="video.vectorVariations.list"
            :default-selected="tempdata.video.type"
            type="video"
            @selectcard="selectVideoType"
          />
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardButton from "@/components/CardButton.vue";
import NavigationPage from "@/components/NavigationPage";

export default {
  name: "VectorVideoType",
  components: {
    CardButton,
    NavigationPage
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters(["video", "tempdata", "videoTypeIsVector"]),
    prevTo() {
      return "video-type";
    },
    nextTo() {
      return this.videoTypeIsVector && "video-config";
    }
  },
  methods: {
    selectVideoType(val) {
      if (val.published) {
        this.$store.dispatch("setVideoType", val);
        this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
        this.$router.push({ name: val.router_name });
      }
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", this.video.vectorVariations.list.length);
    this.$store.dispatch("resetVideoConfig");
    if (!this.videoTypeIsVector) {
      this.$store.dispatch("disableAllVectorOptions");
    }
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.video.type) {
        next();
      } else {
        next({ name: "video-type" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
