<template>
  <div class="page page--banner-status">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="font-primary--light" v-html="edm.status.title"></h1>
          <card-button
            :data="data.list"
            @selectcard="selectEdmStatus"
            :default-selected="tempdata.edm.status"
          ></card-button>
          <NavigationPage :prev-to="prevTo" :next-to="nextTo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavigationPage from "@/components/NavigationPage.vue";
import CardButton from "@/components/CardButton.vue";

export default {
  name: "EdmStatus",
  components: {
    CardButton,
    NavigationPage
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["edm", "tempdata"]),
    data() {
      return this.tempdata.edm.type &&
        this.tempdata.edm.type.status_index_reference > -1
        ? this.edm.status.list[this.tempdata.edm.type.status_index_reference]
        : [];
    },
    nextTo() {
      return (
        this.tempdata.edm.status && this.tempdata.edm.status.id && "edm-design"
      );
    },
    prevTo() {
      return "edm-type";
    }
  },
  methods: {
    selectEdmStatus(val) {
      this.$store.dispatch("setEdmStatus", val);
      this.$store.dispatch("addTempDataToLocalStorage", this.tempdata);
      this.$router.push({ name: this.nextTo });
    }
  },
  mounted() {
    this.$store.dispatch("setGrid", 2);
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("tempdata")) {
      let tempdata = JSON.parse(localStorage.getItem("tempdata"));
      if (tempdata.edm.type && tempdata.edm.type.id) {
        next();
      } else {
        next({ name: "edm-type" });
      }
    } else {
      next({ name: "HomeEstimator" });
    }
  }
};
</script>
