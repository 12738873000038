import product from "./actions/product";
import banner from "./actions/banner";
import video from "./actions/video";
import edm from "./actions/edm";
import allowedEmails from "./actions/allowedEmails";

export default {
  ...product,
  ...banner,
  ...edm,
  ...video,
  ...allowedEmails,
  setTempData: ({ commit }, payload) => {
    commit("setTempData", payload);
  },
  setDetail: ({ commit }, payload) => {
    commit("setDetail", payload);
  },
  setUser: ({ commit }, payload) => {
    commit("setUser", payload);
  },
  setStart: ({ commit }, payload) => {
    commit("setStart", payload);
  },
  setDue: ({ commit }, payload) => {
    commit("setDue", payload);
  },
  setGrid: ({ commit }, payload) => {
    commit("setGrid", payload);
  },
  setModal: ({ commit }, payload) => {
    commit("setModal", payload);
  },
  toggleModal: ({ commit }, payload) => {
    commit("toggleModal", payload);
  },
  addTempDataToLocalStorage: ({ commit }, payload) => {
    commit("addTempDataToLocalStorage", payload);
  },
  resetState: ({ commit, dispatch }) => {
    commit("resetTempData");
    dispatch("setProductState");
    dispatch("setBannerState");
    dispatch("setEdmState");
    dispatch("setVideoState");
  },
  setQuoteID: ({ commit }, payload) => {
    commit("setQuoteID", payload);
  }
};
