<template>
  <div :class="`footer font-primary--light ${result == 'result' ? 'footer-result' : ''}`">
    <div class="container">
      <div class="footer__grid-group" v-if="grid && grid > 0 && result != 'result'">
        <div class="footer__grid" v-for="(item, index) in grid" :key="index"></div>
      </div>
      <div class="footer__grid-group" v-else-if="result == 'result'">
        <div class="footer__grid col-md-4"></div>
        <div class="footer__grid col-md-8"></div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="footer__left">
            <ul class="mb-0 pl-0">
              <li>
                <span class="hidden-pdf">
                  <a href="mailto:wayan.agoes@ogilvy.com"> Contact </a>
                </span>
                <span class="visible-pdf"> wayan.agoes@ogilvy.com </span>
              </li>
              <li>
                <span class="hidden-pdf">
                  <a href="https://www.bullseye-digital.com/" target="_blank"> Visit Bullseye site </a>
                </span>
                <span class="visible-pdf">+62 81805320991</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6">
          <div class="footer__right">
            <p class="mb-0">@ Bullseye 2019</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['grid']),
    result() {
      return this.$route.name;
    },
    className() {
      return this.$route.className;
    }
  }
};
</script>
